import React from "react";
import { Button, Label } from "semantic-ui-react";
import * as Yup from "yup";

export const PROCESS_DSE_TDAY = 10;
export const PROCESS_DSE_T1DAY = 11;
export const PROCESS_NON_DSE_TDAY = 20;
export const PROCESS_NON_DSE_T1DAY = 21;

export const SECURITY_STATUS_PROVISIONAL = "Provisional";
export const SECURITY_STATUS_DRAFTED = "Drafted";
export const SECURITY_STATUS_VERIFICATION = "Marked for Verification";
export const SECURITY_STATUS_REWORK = "Rework";
export const SECURITY_STATUS_APPROVED = "Approved";

export const ALLOCATION_PROCESS_STATUS_STARTED = "Started";
export const ALLOCATION_PROCESS_STATUS_COMPLETED = "Completed";
export const ALLOCATION_PROCESS_STATUS_CANCELLED = "Cancelled";

export const STEP_STATUS_NOT_STARTED = "Not Started";
export const STEP_STATUS_STARTED = "Started";
export const STEP_STATUS_PROCESSING = "Processing";
export const STEP_STATUS_ERROR = "Error";
export const STEP_STATUS_COMPLETED = "Completed";

export const FILE_LIST_TYPE_UPLOAD = "UPLOAD";
export const FILE_LIST_TYPE_DOWNLOAD = "DOWNLOAD";
export const FILE_LIST_TYPE_LOG = "LOG";

export const yupValidationSchema = Yup.object().shape({
  baseQuantity: Yup.number()
    .typeError("Base Quantity must be a valid number")
    .required("Base Quantity is mandatory")
    .positive("Base Quantity must be a valid +ve number")
    .integer("Base Quantity must be a valid number")
    .min(1, "Base Quantity must be a non-zero value"),
  greenshoeQuantity: Yup.number()
    .typeError("Greenshoe Quantity must be a valid number")
    .required("Greenshoe Quantity is mandatory")
    .integer("Greenshoe Quantity must be a valid number")
    .min(0, "Greenshoe Quantity must be a valid number"),
  floorPrice: Yup.number()
    .typeError("Floor Price must be a valid number")
    .required("Floor Price is mandatory")
    .positive("Floor Price must be a valid +ve number")
    .min(0.01, "Floor Price must be a valid number")
    .test(
      "decimalPlaces",
      "Floor Price cannot have more than 2 decimal places",
      (val) => countDecimals(val) <= 2
    ),
  allotmentType: Yup.string()
    .typeError("Allotment Type is mandatory")
    .required("Allotment Type is mandatory"),
  discountFactor: Yup.mixed()
    .test("validDiscountFactor", "Discount Factor must be a valid number", (val) =>
      validFloatNumber(val)
    )
    .test("minDiscountFactor", "Discount Factor must be a valid number between 0 and 100", function(
      val
    ) {
      try {
        const floatVal = parseFloat(val);
        if (floatVal < 0 || floatVal > 100) return false;
        else return true;
      } catch (e) {
        return false;
      }
    })
    .test(
      "decimalPlaces",
      "Discount Factor cannot have more than 3 decimal places",
      (val) => countDecimals(val) <= 3
    ),
  discountType: Yup.mixed()
    .test(
      "discountType",
      "Discount Factor Type is mandatory as Discount Factor is mentioned",
      function(discountType) {
        if (this.parent.discountFactor > 0 && !discountType) return false;
        else return true;
      }
    )
    .test(
      "discountTypeNo",
      "Discount Factor Type must not be selected if Discount Factor is not mentioned",
      function(discountType) {
        if ((!this.parent.discountFactor || this.parent.discountFactor === 0) && discountType)
          return false;
        else return true;
      }
    ),
  riPercent: Yup.mixed()
    .test("validRiPercentage", "Minimum RI Percentage must be a valid number", (val) =>
      validFloatNumber(val)
    )
    .test(
      "minRiPercentage",
      "Minimum RI Percentage must be a valid number between 0 and 100",
      function(val) {
        try {
          const floatVal = parseFloat(val);
          if (floatVal < 0 || floatVal > 100) return false;
          else return true;
        } catch (e) {
          return false;
        }
      }
    )
    .test(
      "decimalPlaces",
      "Minimum RI Percentage cannot have more than 3 decimal places",
      (val) => countDecimals(val) <= 3
    ),
  cutoffFlag: Yup.boolean().typeError("Cutoff Flag is mandatory"),
});

export const countDecimals = (value) => {
  let retVal = 0;
  try {
    if (Math.floor(value) === value) retVal = 0;

    let decimalPlaces = value.toString().split(".")[1];
    if (decimalPlaces) retVal = decimalPlaces.length || 0;

    return retVal;
  } catch (e) {
    return 0;
  }
};

export const renderStatus = (setupData) => {
  if (!setupData) {
    return null;
  }
  let color = "grey";

  if (
    setupData.status === SECURITY_STATUS_DRAFTED ||
    setupData.status === SECURITY_STATUS_REWORK ||
    setupData.status === SECURITY_STATUS_PROVISIONAL
  ) {
    color = "grey";
  } else if (setupData.status === SECURITY_STATUS_VERIFICATION) {
    color = "blue";
  } else if (setupData.status === SECURITY_STATUS_APPROVED) {
    color = "green";
  }
  return (
    <Button disabled color={color} size="mini">
      Status: {setupData.status}
    </Button>
  );
  // return <Message info>{setupData.status}</Message>;
};

export const getIconForProcessType = (step) => {
  if (step.sftpStep) {
    return "file";
  }
  if (step.sqlStep) {
    return "database";
  }
  if (step.fileUpload) {
    return "file upload";
  }
  if (step.fileDownload) {
    return "file download";
  }
  if (step.programStep) {
    return "code";
  }
};

export const renderProcessStepColor = (step) => {
  if (!step) {
    return null;
  }
  let color = "black";

  if (step.status === STEP_STATUS_NOT_STARTED) {
    color = "black";
  } else if (step.status === STEP_STATUS_STARTED || step.status === STEP_STATUS_PROCESSING) {
    color = "blue";
  } else if (step.status === STEP_STATUS_COMPLETED) {
    color = "green";
  } else if (step.status === STEP_STATUS_ERROR) {
    color = "red";
  }
  return color;
};

export const getRowLength = (messageText) => {
  let localMessageText = messageText || "";
  let lineCount = (localMessageText.match(/\n/g) || []).length;

  if (lineCount > 40) return 40;
  else if (lineCount > 5) return lineCount + 1;
  else return 5;
};

export const validFloatNumber = (val) => {
  try {
    if (!val) return true;
    return !isNaN(parseFloat(val));
  } catch (e) {
    return false;
  }
};

export const getProcessTypeText = (processType) => {
  let processTypeText = null;
  switch (processType) {
    case PROCESS_DSE_TDAY:
      processTypeText = "DSE T day";
      break;
    case PROCESS_DSE_T1DAY:
      processTypeText = "DSE T+1 day";
      break;
    case PROCESS_NON_DSE_TDAY:
      processTypeText = "Non-DSE T day";
      break;
    case PROCESS_NON_DSE_T1DAY:
      processTypeText = "Non-DSE T+1 day";
      break;
  }
  return processTypeText;
};

export const getStpNumberForProcess = (setupData, processType) => {
  if (processType === PROCESS_DSE_TDAY || processType === PROCESS_NON_DSE_TDAY) {
    return setupData.tdayStp;
  } else if (processType === PROCESS_DSE_T1DAY || processType === PROCESS_NON_DSE_T1DAY) {
    return setupData.t1dayStp;
  }
};
