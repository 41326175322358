import React from "react";

import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";

import asyncComponent from "./utils/AsyncComponent";
import { asyncComponent as reactAsyncComponent } from "react-async-component";

import ProtectedRoute from "./utils/ProtectedRoute";

import TopBar from "./TopBar";
import NavigationHeader from "./NavigationHeader";
import MessageWindow from "./dashboard/MessageWindow";
import FooterBar from "./FooterBar";
import Dashboard from "./Dashboard";
import DemandFileUpload from "./admin/DemandFileUpload";
import DemandFileUploadLog from "./admin/DemandFileUploadLog";
const UserMFASettings = reactAsyncComponent({
  resolve: () => import("./masters/mfa/UserMFASettings")
});
const PriceSettings = reactAsyncComponent({
  resolve: () => import("./masters/PriceSettings")
});

const getAsync = importComponent => {
  return reactAsyncComponent({
    resolve: () => importComponent,
    LoadingComponent: () => <h1>Loading...</h1>,
    ErrorComponent: () => <div>Error occured, Please refresh the page.</div>
  });
};
const HolidayMaster = getAsync(import("./masters/HolidayMaster"));
const BranchList = reactAsyncComponent({ resolve: () => import("./masters/BranchList") });
const BranchListPaging = reactAsyncComponent({
  resolve: () => import("./masters/BranchListPaging")
});
const BranchListPagingViewOnly = reactAsyncComponent({
  resolve: () => import("./masters/BranchListPagingViewOnly")
});
const BranchView = reactAsyncComponent({ resolve: () => import("./masters/BranchView") });
const BranchViewOnly = reactAsyncComponent({ resolve: () => import("./masters/BranchViewOnly") });

const BranchEdit = reactAsyncComponent({ resolve: () => import("./masters/BranchEdit") });
const BranchCreation = reactAsyncComponent({ resolve: () => import("./masters/BranchCreation") });

const ParticipantList = reactAsyncComponent({ resolve: () => import("./masters/ParticipantList") });
const TradingMemberList = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberList")
});


const TradingMemberListViewOnly = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberListViewOnly")
});

const UserList = reactAsyncComponent({ resolve: () => import("./masters/UserList") });
const UserListPaging = reactAsyncComponent({ resolve: () => import("./masters/UserListPaging") });
const UserListPagingViewOnly = reactAsyncComponent({ resolve: () => import("./masters/UserListPagingViewOnly") });
const ExUserListPaging = reactAsyncComponent({ resolve: () => import("./masters/ExUserPaging") });
const UserView = reactAsyncComponent({ resolve: () => import("./masters/UserView") });
const UserViewOnly = reactAsyncComponent({ resolve: () => import("./masters/UserViewOnly") });
const UserEdit = reactAsyncComponent({ resolve: () => import("./masters/UserEdit") });
const UserCreation = reactAsyncComponent({ resolve: () => import("./masters/UserCreation") });
const SecurityList = reactAsyncComponent({
  resolve: () => import("./masters/security/SecurityList")
});
const SecurityListViewOnly = reactAsyncComponent({
  resolve: () => import("./masters/security/SecurityListViewOnly")
});
const ParticipantView = reactAsyncComponent({ resolve: () => import("./masters/ParticipantView") });
const ParticipantForm = reactAsyncComponent({ resolve: () => import("./masters/ParticipantForm") });
const TradingMemberView = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberView")
});
const TradingMemberViewOnly = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberViewOnly")
});

const TradingMemberEdit = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberEdit")
});
const TradingMemberCreation = reactAsyncComponent({
  resolve: () => import("./masters/TradingMemberCreation")
});
const OrderEntry = reactAsyncComponent({ resolve: () => import("./orderManagement/OrderEntry") });
const OrderModify = reactAsyncComponent({ resolve: () => import("./orderManagement/OrderModify") });
const OrderCancel = reactAsyncComponent({ resolve: () => import("./orderManagement/OrderCancel") });
const OrderBulkUpload = reactAsyncComponent({
  resolve: () => import("./orderManagement/OrderBulkUpload")
  
});
const OrderLog = reactAsyncComponent({ resolve: () => import("./orderManagement/OrderLog") });
const CurrentOrderStatus = reactAsyncComponent({ resolve: () => import("./orderManagement/CurrentOrderStatus") })
const CarryFwdExchangeUpload = reactAsyncComponent({
  resolve: () => import("./carryforward/CarryFwdExchangeUpload")
});
const CarryFwdExchangeUploadLog = reactAsyncComponent({
  resolve: () => import("./carryforward/CarryFwdExchangeUploadLog")
});

const CFModify = reactAsyncComponent({ resolve: () => import("./carryforward/CFModify") });
const MasterBulkUpload = reactAsyncComponent({
  resolve: () => import("./masters/MasterBulkUpload")
});
const MasterBulkUploadLog = reactAsyncComponent({
  resolve: () => import("./masters/MasterBulkUploadLog")
});
const ClientMasterReport = reactAsyncComponent({
  resolve: () => import("./masters/ClientMasterReport")
});
const ClientCreation = reactAsyncComponent({
  resolve: () => import("./masters/ClientCreation")
});
const ClientEdit = reactAsyncComponent({
  resolve: () => import("./masters/ClientEdit")
});
const ClientView = reactAsyncComponent({
  resolve: () => import("./masters/ClientView")
});
const ClientViewForDealer = reactAsyncComponent({
  resolve: () => import("./masters/ClientViewForDealer")
});
const OrderReport = reactAsyncComponent({ resolve: () => import("./orderManagement/OrderReport") });
const BatchOrder = reactAsyncComponent({ resolve: () => import("./orderManagement/BatchOrder") });
const ErrorOrderReport = reactAsyncComponent({
  resolve: () => import("./orderManagement/ErrorOrderReport")
});
const CarryFwdReport = reactAsyncComponent({
  resolve: () => import("./carryforward/CarryFwdReport")
});
const MBP = reactAsyncComponent({ resolve: () => import("./dashboard/MBP") });
const ActiveSecurities = reactAsyncComponent({
  resolve: () => import("./dashboard/ActiveSecurities")
});

const SecuritySetup = reactAsyncComponent({
  resolve: () => import("./masters/security/SecuritySetup")
});
const SecuritySetupModify = reactAsyncComponent({
  resolve: () => import("./masters/security/SecuritySetupModify")
});
const SecuritySetupTemplate = reactAsyncComponent({
  resolve: () => import("./masters/security/SecuritySetupTemplate")
});
const DraftSecurityList = reactAsyncComponent({
  resolve: () => import("./masters/security/DraftSecurityList")
});
const SecuritySetupDraft = reactAsyncComponent({
  resolve: () => import("./masters/security/SecuritySetupDraft")
});
const SecurityDraftModify = reactAsyncComponent({
  resolve: () => import("./masters/security/SecurityDraftModify")
});
const Broadcast = reactAsyncComponent({ resolve: () => import("./admin/Broadcast") });
const MessageBroadcastLog = reactAsyncComponent({
  resolve: () => import("./admin/MessageBroadcastLog")
});
const InterfaceConnectivityStatus = reactAsyncComponent({
  resolve: () => import("./admin/InterfaceConnectivityStatus")
});
const ChangeMarketStatus = reactAsyncComponent({
  resolve: () => import("./admin/ChangeMarketStatus")
});
const MarketStatusChangeLog = reactAsyncComponent({
  resolve: () => import("./admin/MarketStatusChangeLog")
});
const MastersAuditLog = reactAsyncComponent({ resolve: () => import("./admin/MastersAuditLog") });

const TMReconciliationLogs = reactAsyncComponent({ resolve: () => import("./admin/TMReconciliationLogs") });
const TMReconciliationErrorLogs = reactAsyncComponent({ resolve: () => import("./admin/TMReconciliationErrorLogs") });
const CircuitBreaker = reactAsyncComponent({ resolve: () => import("./admin/CircuitBreaker") });
const CircuitBreakerLog = reactAsyncComponent({
  resolve: () => import("./admin/CircuitBreakerLog")
});
const WebsiteFileGeneration = reactAsyncComponent({ resolve: () => import("./admin/WebsiteFileGeneration" ) });
const TaskSchedulerLog = reactAsyncComponent({ resolve: () => import("./admin/TaskSchedulerLog") });
const SymbolwiseArchival = reactAsyncComponent({
  resolve: () => import("./admin/SymbolwiseArchival")
});
const ArchivalLogs = reactAsyncComponent({ resolve: () => import("./admin/ArchivalLogs") });
const Eod = reactAsyncComponent({ resolve: () => import("./admin/Eod") });
const EODLogs = reactAsyncComponent({ resolve: () => import("./admin/EODLogs") });
const ChecksumLogs = reactAsyncComponent({ resolve: () => import("./admin/ChecksumLogs") });

const ActiveUsers = reactAsyncComponent({ resolve: () => import("./admin/ActiveUsers") });
const RMSServerMessages = reactAsyncComponent({
  resolve: () => import("./admin/RMSServerMessages")
});
const RMSReceiverMessages = reactAsyncComponent({
  resolve: () => import("./admin/RMSReceiverMessages")
});
const CPApproveReceiverMessages = reactAsyncComponent({
  resolve: () => import("./admin/CPApproveReceiverMessages")
});
const CombinerMessages = reactAsyncComponent({
  resolve: () => import("./admin/CombinerMessages")
});

const FileGenTracker = reactAsyncComponent({
  resolve: () => import("./admin/FileGenTracker")
});

const LICReport = reactAsyncComponent({
  resolve: () => import("./admin/LICReport")
});

const LICReportViewOnly = reactAsyncComponent({
  resolve: () => import("./admin/LICReportViewOnly")
});

const MOFReport = reactAsyncComponent({
  resolve: () => import("./admin/MOFReport")
});

const NSEBidFileGeneration = reactAsyncComponent({
  resolve: () => import("./allocation/NSEBidFileGeneration")
});

const NSEBidFileGenLogs = reactAsyncComponent({
  resolve: () => import("./allocation/NSEBidFileGenLogs")
});

const AllocationMasterSetupList = reactAsyncComponent({
  resolve: () => import("./allocation/AllocationMasterSetupList")
});

const AllocationMasterSetupDraft = reactAsyncComponent({
  resolve: () => import("./allocation/AllocationMasterSetupDraft")
});
const AllocationMasterSetupView = reactAsyncComponent({
  resolve: () => import("./allocation/AllocationMasterSetupView")
});
const AllocationProcess = reactAsyncComponent({
  resolve: () => import("./allocation/AllocationProcess")
});
const AllocationProcessList = reactAsyncComponent({
  resolve: () => import("./allocation/AllocationProcessList")
});

const Home = props => (
  <div className="site">
    <div className="nse-topbar" style={{ paddingBottom: 5 }}>
      <TopBar />
      <NavigationHeader />
    </div>
    <div className="site-content" style={{ paddingBottom: 5 }}>
      <Container>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/masters/branch" exact component={BranchListPaging} />
          <Route path="/masters/branch-viewonly" exact component={BranchListPagingViewOnly} />
          <Route path="/masters/branch/:tradingMemberId/:branchId" exact component={BranchView} />
          <Route path="/masters/branch-view/:tradingMemberId/:branchId" exact component={BranchViewOnly} />
          
          <Route
            path="/masters/branch-edit/:tradingMemberId/:branchId"
            exact
            component={BranchEdit}
          />
          <Route path="/masters/branch-create" exact component={BranchCreation} />
          <Route path="/masters/participant" exact component={ParticipantList} />
          <Route path="/masters/participant/:participantId" exact component={ParticipantView} />
          <Route path="/masters/participantForm" exact component={ParticipantForm} />
          <Route path="/masters/tradingmember" exact component={TradingMemberList} />
          <Route path="/masters/tradingmember-viewonly" exact component={TradingMemberListViewOnly} />
          <Route
            path="/masters/tradingmember/:tradingMemberId"
            exact
            component={TradingMemberView}
          />
          <Route
            path="/masters/tradingmember-viewone/:tradingMemberId"
            exact
            component={TradingMemberViewOnly}
          />
          <Route
            path="/masters/tradingmember-edit/:tradingMemberId"
            exact
            component={TradingMemberEdit}
          />
          <Route path="/masters/tradingmember-create" exact component={TradingMemberCreation} />
          <Route
            path="/securities"
            exact
            component={() => <ActiveSecurities isDashboard={false} />}
          />
          <Route path="/mbp" exact component={MBP} />

          {/* <Route path="/masters/user" exact component={UserList} /> */}
          <Route path="/masters/user" exact component={UserListPaging} />
          <Route path="/masters/user-viewonly" exact component={UserListPagingViewOnly} />
          <Route path="/masters/exchg-user" exact component={ExUserListPaging} />
          <Route path="/masters/user/:userId" exact component={UserView} />
          <Route path="/masters/userview/:userId" exact component={UserViewOnly} />
          
          <Route path="/masters/user-edit/:userId" exact component={UserEdit} />
          <Route path="/masters/user-create" exact component={UserCreation} />
          <Route path="/masters/bulkupload" exact component={MasterBulkUpload} />
          <Route path="/masters/bulkupload/:batchId" exact component={MasterBulkUpload} />
          <Route path="/masters/bulkupload-log" exact component={MasterBulkUploadLog} />
          <Route path="/masters/client-master" exact component={ClientMasterReport} />
          <Route path="/masters/client-master-create" exact component={ClientCreation} />
          <Route path="/masters/client-master-edit/:id" exact component={ClientEdit} />
          <Route path="/masters/client-master/:id" exact component={ClientView} />
          <Route path="/masters/client-master-dealer/:id" exact component={ClientViewForDealer} />
          
          <Route path="/masters/security" exact component={SecurityList} />
          <Route path="/masters/security-viewonly" exact component={SecurityListViewOnly} />
          <Route path="/masters/securitysetup" exact component={SecuritySetup} />
          <Route path="/masters/securitymodify/:id" exact component={SecuritySetupModify} />
          <Route path="/masters/securitytemplate" exact component={SecuritySetupTemplate} />
          <Route path="/masters/draft-security-list" exact component={DraftSecurityList} />
          <Route path="/masters/draft-security-create" exact component={SecuritySetupDraft} />
          <Route path="/masters/draft-security-modify/:id" exact component={SecurityDraftModify} />
          <Route path="/masters/holiday-master" exact component={HolidayMaster} />

          <Route path="/masters/mfa-settings" exact component={UserMFASettings} />
          <Route path="/masters/price-settings" exact component={PriceSettings} />

          <ProtectedRoute path="/order/entry/:symbol/:series" exact component={OrderEntry} />
          <ProtectedRoute path="/order/entry" exact component={OrderEntry} />
          <ProtectedRoute path="/order/modify" exact component={OrderModify} />
          <ProtectedRoute path="/order/modify/:orderId" exact component={OrderModify} />
          <ProtectedRoute path="/order/cancel/:orderId" exact component={OrderCancel} />
          <ProtectedRoute path="/order/cancel" exact component={OrderCancel} />
          <Route path="/order/bulkupload" exact component={OrderBulkUpload} />
          <Route path="/cf/exchange-upload" exact component={CarryFwdExchangeUpload} />
          <Route path="/cf/exchange-upload/:batchId" exact component={CarryFwdExchangeUpload} />
          <Route path="/cf/exchange-upload-log" exact component={CarryFwdExchangeUploadLog} />

          <Route path="/order/report" exact component={OrderReport} />
          <Route path="/order/errororders" exact component={ErrorOrderReport} />
          <Route path="/order/batchOrder" exact component={BatchOrder} />
          <Route path="/order/orderLog" exact component={OrderLog} />
          <Route path="/order/currentOrderStatus" exact component={CurrentOrderStatus} />
          <Route path="/cf/report" exact component={CarryFwdReport} />
          <Route path="/cf/modify/:orderId" exact component={CFModify} />
          

          <Route path="/utils/broadcast" exact component={Broadcast} />
          <Route path="/utils/broadcast-log" exact component={MessageBroadcastLog} />
          <Route path="/utils/interfacestatus" exact component={InterfaceConnectivityStatus} />
          <Route path="/utils/change-mkt-status" exact component={ChangeMarketStatus} />
          <Route path="/utils/mkt-status-log" exact component={MarketStatusChangeLog} />
          <Route path="/utils/WebsitefileGeneration"exact component={WebsiteFileGeneration} />
          <Route path="/utils/master-audit-log" exact component={MastersAuditLog} />
          <Route path="/utils/tm-reconciliation-logs" exact component={TMReconciliationLogs} />
          <Route path="/utils/tm-reconciliation-error-logs" exact component={TMReconciliationErrorLogs} />
          <Route path="/utils/circuit-breaker" exact component={CircuitBreaker} />
          <Route path="/utils/circuit-breaker-log" exact component={CircuitBreakerLog} />
          <Route path="/utils/task-scheduler-log" exact component={TaskSchedulerLog} />
          <Route path="/utils/symbolwise-archival" exact component={SymbolwiseArchival} />
          <Route path="/utils/archival-log" exact component={ArchivalLogs} />
          <Route path="/utils/eod" exact component={Eod} />
          <Route path="/utils/eod-logs" exact component={EODLogs} />
          <Route path="/utils/checksum-logs" exact component={ChecksumLogs} />
          <Route path="/utils/active-users" exact component={ActiveUsers} />
          <Route path="/utils/rms-server-monitoring" exact component={RMSServerMessages} />
          <Route path="/utils/rms-receiver-monitoring" exact component={RMSReceiverMessages} />
          <Route path="/utils/cp-approve-monitoring" exact component={CPApproveReceiverMessages} />
          <Route path="/utils/combiner-monitoring" exact component={CombinerMessages} />
          <Route path="/utils/bse-filegen-tracker" exact component={FileGenTracker} />
          <Route path="/filegen-tracker/demandfileupload" exact component={DemandFileUpload} />
          <Route path="/filegen-tracker/demandfileupload-log" exact component={DemandFileUploadLog} />
          
         

          <Route
            path="/utils/client-report"
            exact
            component={() => <LICReport isDashboard={false} />}
          />
          <Route
            path="/utils/client-report-view-only"
            exact
            component={() => <LICReportViewOnly isDashboard={false} />}
          />
          <Route
            path="/utils/mof-report"
            exact
            component={() => <MOFReport isDashboard={false} />}
          />

          <Route path="/allocation/nse-bid-filegen" exact component={NSEBidFileGeneration} />
          <Route path="/allocation/nse-bid-filegen-logs" exact component={NSEBidFileGenLogs} />
          <Route
            path="/allocation/master-setup-list/:status"
            exact
            component={AllocationMasterSetupList}
          />
          <Route
            path="/allocation/master-setup/new"
            exact
            render={props => <AllocationMasterSetupDraft isNewSetup={true} {...props} />}
          />
          <Route
            path="/allocation/master-setup/view/:id"
            exact
            component={AllocationMasterSetupView}
          />
          <Route
            path="/allocation/master-setup/mod/:id"
            exact
            component={AllocationMasterSetupDraft}
          />
          <Route
            path="/allocation/process/start"
            exact
            render={props => <AllocationProcess isStart={true} {...props} />}
          />
          <Route path="/allocation/process/view/:id" exact component={AllocationProcess} />
          <Route path="/allocation/process/list" exact component={AllocationProcessList} />
        </Switch>
      </Container>
    </div>
    <MessageWindow />
    <FooterBar />
  </div>
);

export default Home;
