import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import App from "./components/App";
import store from "./reducers/store";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(reduxThunk))
// );
require("dotenv").config();

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </CookiesProvider>,
  document.querySelector("#root")
);
