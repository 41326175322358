import React from "react";

import { Menu, Dropdown, Modal, Button, Icon, Container, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import store from "../reducers/store";
import { signOut } from "../actions";
import ChangePassword from "./ChangePassword";
import SessionExpiryNotification from "./utils/SessionExpiryNotification";
import { API_BASE_URL } from "../applicationConstants";
import {
  isBranchManager,
  isCorporateManager,
  isMemberSideRole,
  isControlUser,
  isViewOnlyUser,
  isLicUser,
  isMofUser,
  isListcoUser,
  isInquiryUser
} from "../utils/commonUtils";

class NavigationHeader extends React.Component {
  state = {
    isSignoutModalOpen: false,
    isChangePasswordModalOpen: false,
    auth: null,
    activeItem: "dashboard",
    isSignoutProcessing: false
  };

  isKeyEventListenerRegistered = false;

  constructor() {
    super();

    store.subscribe(() => {
      this.setState({
        auth: store.getState().auth
      });
    });

    // window.setTimeout(() => this.setState({ isExtendSessionOpen: true }), 3600);
  }

  captureFunctionKeys(event) {
    // F1 = 112
    // F2 = 113
    // F3 = 114
    // F6 = 117
    if (event.keyCode === 112) {
      event.preventDefault();
      window.location.hash = "/order/entry";
    } else if (event.keyCode === 113) {
      event.preventDefault();
      window.location.hash = "/order/cancel";
    } else if (event.keyCode === 114) {
      event.preventDefault();
      window.location.hash = "/order/modify";
    } else if (event.keyCode === 117) {
      event.preventDefault();
      window.location.hash = "/mbp";
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.captureFunctionKeys, false);
    this.isKeyEventListenerRegistered = true;
  }

  componentDidUpdate() {
    const { auth } = this.state;
    if (
      this.isKeyEventListenerRegistered &&
      auth &&
      auth.userInfo &&
      !isMemberSideRole(auth.userInfo.roles[0])
    ) {
      this.isKeyEventListenerRegistered = false;
      document.removeEventListener("keydown", this.captureFunctionKeys, false);
    }
  }

  componentWillUnmount() {
    this.isKeyEventListenerRegistered = false;
    document.removeEventListener("keydown", this.captureFunctionKeys, false);
  }

  handleItemClick = (e, { name }) => {
    console.log(name);
    this.setState({ activeItem: name });
  };

  handleFileDownload = () => {
    window.open(`${API_BASE_URL}/query/downloadUserManual`, "_blank");
  };

  handleSignOutModalOpen = () => {
    this.setState({ isSignoutModalOpen: !this.state.isSignoutModalOpen });
  };

  handleSignOutModalClose = () => {
    this.setState({ isSignoutModalOpen: false });
  };

  handleChangePasswordModalOpen = () => {
    this.setState({ isChangePasswordModalOpen: true }, () => console.log(this.state));
  };

  handleChangePasswordModalClose = () => {
    this.setState({ isChangePasswordModalOpen: false });
  };

  handleSignOut = () => {
    const cookies = new Cookies();
    const token = cookies.get("IDENTITY");
    this.setState({ isSignoutProcessing: true });
    this.props.signOut(token);
  };

  renderTextWithIcon = (text, icon) => {
    if (icon) {
      return (
        <span>
          <Icon name={icon} />
          &nbsp;
          {text}
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  renderSignoutModal = () => {
    const { isSignoutModalOpen, isSignoutProcessing } = this.state;
    return (
      <Modal size="tiny" onClose={this.handleSignOutModalClose} open={isSignoutModalOpen} closeIcon>
        <Modal.Header>
          <Icon name="sign out" />
          Sign Out
        </Modal.Header>
        <Modal.Content>
          <p>Do you want to sign out from the OFS system?</p>
          <br />
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              negative
              onClick={this.handleSignOut}
              content="Yes"
              icon="check"
              labelPosition="left"
              loading={isSignoutProcessing}
            />
            <Button.Or />
            <Button
              onClick={this.handleSignOutModalClose}
              content="No"
              icon="close"
              labelPosition="right"
            />
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  };

  renderExchangeUserNav = () => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            <Icon name="dashboard" />
            Dashboard
          </Menu.Item>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Masters", "book")}
            className={activeItem === "masters" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="chart line"
                text="Securities"
                as={Link}
                to="/masters/security"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="chart line"
                text="Draft Securities"
                as={Link}
                to="/masters/draft-security-list"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="address book"
                text="User Management"
                as={Link}
                to="/masters/user"
                name="masters"
                onClick={this.handleItemClick}
              />
               <Dropdown.Item
                icon="address book"
                text="Exchange User Management"
                as={Link}
                to="/masters/exchg-user"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="user secret"
                text="Trading Members"
                as={Link}
                to="/masters/tradingMember"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="code branch"
                text="Branches"
                as={Link}
                to="/masters/branch"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="money"
                text="Participants"
                as={Link}
                to="/masters/participant"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="calendar alternate outline"
                text="Holiday Master"
                as={Link}
                to="/masters/holiday-master"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="upload"
                text="Master Bulk Upload"
                as={Link}
                to="/masters/bulkupload"
                name="masters"
                onClick={this.handleItemClick}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Reports", "chart line")}
            className={activeItem === "reports" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="book"
                text="Order Book Report"
                as={Link}
                to="/order/report"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file excel"
                text="Error Order Report"
                as={Link}
                to="/order/errororders"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file"
                text="Batch Orders"
                as={Link}
                to="/order/batchOrder"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="mail forward"
                text="Carry forward orders"
                as={Link}
                to="/cf/report"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="history"
                text="Order Log Report"
                as={Link}
                to="/order/orderLog"
                name="orderLog"
                onClick={this.handleItemClick}
              />
               <Dropdown.Item
                icon="check"
                text="Current Order Status"
                as={Link}
                to="/order/currentOrderStatus"
                name="currentOrderStatus"
                onClick={this.handleItemClick}
              />  
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            item
            trigger={this.renderTextWithIcon("Utilities", "cogs")}
            className={activeItem === "utilities" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="bullhorn"
                text="Broadcast"
                as={Link}
                to="/utils/broadcast"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="rss"
                text="Interface Connectivity Status"
                as={Link}
                to="/utils/interfacestatus"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="upload"
                text="Carry-forward upload"
                as={Link}
                to="/cf/exchange-upload"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="sliders horizontal"
                text="Change System Status"
                as={Link}
                to="/utils/change-mkt-status"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="stop circle"
                text="Circuit Breaker"
                as={Link}
                to="/utils/circuit-breaker"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="history"
                text="Master Audit log"
                as={Link}
                to="/utils/master-audit-log"
                onClick={this.handleItemClick}
                name="utilities"
              />

              <Dropdown.Item
                icon="history"
                text="TM Reconciliation Logs"
                as={Link}
                to="/utils/tm-reconciliation-logs"
                onClick={this.handleItemClick}
                name="utilities"
              />

              <Dropdown.Item
                icon="file"
                text="Generate Website File"
                as={Link}
                to="/utils/WebsiteFileGeneration"
                onClick={this.handleItemClick}
                name="utilities"
              />

              <Dropdown.Item
                icon="history"
                text="TM Reconciled Error Logs"
                as={Link}
                to="/utils/tm-reconciliation-error-logs"
                onClick={this.handleItemClick}
                name="utilities"
              />

              <Dropdown.Item
                icon="tasks"
                text="Task Scheduler Log"
                as={Link}
                to="/utils/task-scheduler-log"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="archive"
                text="Archival"
                as={Link}
                to="/utils/symbolwise-archival"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="clipboard list"
                text="EOD"
                as={Link}
                to="/utils/eod"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="users"
                text="Active Users"
                as={Link}
                to="/utils/active-users"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="users"
                text="RMS Server Monitoring"
                as={Link}
                to="/utils/rms-server-monitoring"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="file alternate outline"
                text="File generation tracker"
                as={Link}
                to="/utils/bse-filegen-tracker"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="file alternate outline"
                text="Client Report"
                as={Link}
                to="/utils/client-report"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="file alternate outline"
                text="MoF Report"
                as={Link}
                to="/utils/mof-report"
                onClick={this.handleItemClick}
                name="utilities"
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Allocation", "clipboard list")}
            className={activeItem === "allocation" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="file"
                text="NSE Trade file generation"
                as={Link}
                to="/allocation/nse-bid-filegen"
                onClick={this.handleItemClick}
                name="allocation"
              />
              <Dropdown.Item
                icon="lightning"
                text="Start Post-Allocation Process"
                as={Link}
                to="/allocation/process/start"
                onClick={this.handleItemClick}
                name="allocation"
              />
              <Dropdown.Item
                icon="list"
                text="Allocation Processes"
                as={Link}
                to="/allocation/process/list"
                onClick={this.handleItemClick}
                name="allocation"
              />
            </Dropdown.Menu>
          </Dropdown>
          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };

  renderViewOnlyUserNav = () => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            <Icon name="dashboard" />
            Dashboard
          </Menu.Item>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Masters", "book")}
            className={activeItem === "masters" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="chart line"
                text="Securities"
                as={Link}
                to="/masters/security-viewonly"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="address book"
                text="User Management"
                as={Link}
                to="/masters/user-viewonly"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="user secret"
                text="Trading Members"
                as={Link}
                to="/masters/tradingMember-viewonly"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="code branch"
                text="Branches"
                as={Link}
                to="/masters/branch-viewonly"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="money"
                text="Participants"
                as={Link}
                to="/masters/participant"
                name="masters"
                onClick={this.handleItemClick}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Utilities", "cogs")}
            className={activeItem === "utilities" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="file alternate outline"
                text="Client Report"
                as={Link}
                to="/utils/client-report-view-only"
                onClick={this.handleItemClick}
                name="utilities"
              />
              <Dropdown.Item
                icon="file alternate outline"
                text="MoF Report"
                as={Link}
                to="/utils/mof-report"
                onClick={this.handleItemClick}
                name="utilities"
              />
            </Dropdown.Menu>
          </Dropdown>
          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };


  renderDealerNav = userRole => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            <Icon name="dashboard" />
            Dashboard
          </Menu.Item>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Masters", "book")}
            className={activeItem === "masters" ? "active" : undefined}
          >
            <Dropdown.Menu>
              {isBranchManager(userRole) || isCorporateManager(userRole) ? (
                <React.Fragment>
                  <Dropdown.Item
                    icon="address book"
                    text="User Management"
                    as={Link}
                    to="/masters/user"
                    name="masters"
                    onClick={this.handleItemClick}
                  />
                  {isCorporateManager(userRole) ? (
                    <Dropdown.Item
                      icon="code branch"
                      text="Branches"
                      as={Link}
                      to="/masters/branch"
                      name="masters"
                      onClick={this.handleItemClick}
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
              <Dropdown.Item
                icon="upload"
                text="Master Bulk Upload"
                as={Link}
                to="/masters/bulkupload"
                name="masters"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="list"
                text="Client Master"
                as={Link}
                to="/masters/client-master"
                name="masters"
                onClick={this.handleItemClick}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Order Management", "shopping cart")}
            className={activeItem === "order" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="book"
                text="Entry"
                as={Link}
                to="/order/entry"
                name="order"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="edit"
                text="Modify"
                as={Link}
                to="/order/modify"
                name="order"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file excel"
                text="Cancel"
                as={Link}
                to="/order/cancel"
                name="order"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="upload"
                text="Bulk Upload"
                as={Link}
                to="/order/bulkupload"
                name="order"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="mail forward"
                text="Carry forward orders"
                as={Link}
                to="/cf/report"
                name="order"
                onClick={this.handleItemClick}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Reports", "chart line")}
            className={activeItem === "reports" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="book"
                text="Order Book Report"
                as={Link}
                to="/order/report"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file excel"
                text="Error Order Report"
                as={Link}
                to="/order/errororders"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file"
                text="Batch Orders"
                as={Link}
                to="/order/batchOrder"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="history"
                text="Order Log Report"
                as={Link}
                to="/order/orderLog"
                name="orderLog"
                onClick={this.handleItemClick}
              />
              
            </Dropdown.Menu>
          </Dropdown>

          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };

  renderInquiryNav = () => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            {/* <img src="/nse_logo.png" alt="nse-logo" /> */}
            <Icon name="dashboard" />
            Dashboard
            {/* <Image src="/nse_logo.png" size="small" verticalAlign="middle" /> */}
          </Menu.Item>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Reports", "chart line")}
            className={activeItem === "reports" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="book"
                text="Order Book Report"
                as={Link}
                to="/order/report"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file excel"
                text="Error Order Report"
                as={Link}
                to="/order/errororders"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="file"
                text="Batch Orders"
                as={Link}
                to="/order/batchOrder"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="mail forward"
                text="Carry forward orders"
                as={Link}
                to="/cf/report"
                name="reports"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="history"
                text="Order Log Report"
                as={Link}
                to="/order/orderLog"
                name="orderLog"
                onClick={this.handleItemClick}
              />
              
            </Dropdown.Menu>
          </Dropdown>

          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };

  renderLicNav = () => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            {/* <img src="/nse_logo.png" alt="nse-logo" /> */}
            <Icon name="dashboard" />
            Dashboard
            {/* <Image src="/nse_logo.png" size="small" verticalAlign="middle" /> */}
          </Menu.Item>
          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };

  renderListcoUserNav = () => {
    const { activeItem } = this.state;
    return (
      <Container>
        <Menu stackable color="violet" inverted>
          <Menu.Item
            name="dashboard"
            active={activeItem === "dashboard"}
            onClick={this.handleItemClick}
            as={Link}
            to="/"
          >
            <Icon name="dashboard" />
            Dashboard
          </Menu.Item>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Master Setup", "clipboard list")}
            className={activeItem === "mastersetup" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="file"
                text="New Master Setup"
                as={Link}
                to="/allocation/master-setup/new"
                onClick={this.handleItemClick}
                name="mastersetup"
              />
              <Dropdown.Item
                icon="unordered list"
                text="Master Setup - All"
                as={Link}
                to="/allocation/master-setup-list/all"
                onClick={this.handleItemClick}
                name="mastersetup"
              />
              {/* <Dropdown.Item
                icon="cogs"
                text="Master Setup - Drafted"
                as={Link}
                to="/allocation/master-setup-list/drafted"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="cogs"
                text="Master Setup - Marked for Verification"
                as={Link}
                to="/allocation/master-setup-list/verification"
                onClick={this.handleItemClick}
              />
              <Dropdown.Item
                icon="cogs"
                text="Master Setup - Rework"
                as={Link}
                to="/allocation/master-setup-list/rework"
                onClick={this.handleItemClick}
              /> */}
              <Dropdown.Item
                icon="check circle"
                text="Master Setup - Approved"
                as={Link}
                to="/allocation/master-setup-list/approved"
                onClick={this.handleItemClick}
                name="mastersetup"
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            item
            trigger={this.renderTextWithIcon("Allocation", "clipboard list")}
            className={activeItem === "allocation" ? "active" : undefined}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="lightning"
                text="Start Allocation Process"
                as={Link}
                to="/allocation/process/start"
                onClick={this.handleItemClick}
                name="allocation"
              />
              <Dropdown.Item
                icon="list"
                text="Allocation Processes"
                as={Link}
                to="/allocation/process/list"
                onClick={this.handleItemClick}
                name="allocation"
              />
            </Dropdown.Menu>
          </Dropdown>
          {this.renderRightSideNav(activeItem)}
          {this.renderSignoutModal()}
        </Menu>
      </Container>
    );
  };

  renderRightSideNav = activeItem => {
    const { auth } = this.state;
    const userRole = auth.userInfo.roles[0];

    return (
      <Menu.Menu position="right">
         {isControlUser(userRole) || isListcoUser(userRole) || isViewOnlyUser(userRole)? null : (
        <Dropdown
          item
          trigger={this.renderTextWithIcon("", "user")}
          className={activeItem === "order" ? "active" : undefined}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon="cogs"
              text="MFA Settings"
              as={Link}
              to="/masters/mfa-settings"
              onClick={this.handleItemClick}
            />
           <Dropdown.Item
                icon="edit outline"
                text="Change Password"
                onClick={this.handleChangePasswordModalOpen}
              />
            {isCorporateManager(userRole) ? (
              <Dropdown.Item
                icon="cogs"
                text="Limit Setting"
                as={Link}
                to="/masters/price-settings"
                name="masters"
                onClick={this.handleItemClick}
              />
            ) : null}
            </Dropdown.Menu>
        </Dropdown>
        )}
        <Menu.Item
          name="signout"
          active={activeItem === "signout"}
          onClick={this.handleSignOutModalOpen}
        >
          <Icon name="sign-out" /> Sign Out
        </Menu.Item>

        <Menu.Item name="help" active={activeItem === "help"} onClick={this.handleFileDownload}>
          {this.renderTextWithIcon("Help", "help circle")}
        </Menu.Item>
        <ChangePassword
          isChangePasswordModalOpen={this.state.isChangePasswordModalOpen}
          onClose={this.handleChangePasswordModalClose}
        />
        {window.Notification ? <SessionExpiryNotification /> : null}
      </Menu.Menu>
    );
  };

  render() {
    const { activeItemm, auth } = this.state;

    if (!auth || !auth.userInfo) {
      // return <PageLoader loaderText="Loading navigation..." />;
      return (
        <Container textAlign="center">
          <Menu stackable color="violet" inverted>
            <Loader active inline size="small" />
          </Menu>
        </Container>
      );
    }

    const userRole = auth.userInfo.roles[0];

    if (isMemberSideRole(userRole)) {
      return this.renderDealerNav(userRole);
    } else if (isInquiryUser(userRole)) {
      return this.renderInquiryNav();
    } else if (isControlUser(userRole)) {
      return this.renderExchangeUserNav();
    } else if (isListcoUser(userRole)) {
      return this.renderListcoUserNav();
    } else if (isLicUser(userRole) || isMofUser(userRole)) {
      return this.renderLicNav();
    } else if (isViewOnlyUser(userRole)) {
      return this.renderViewOnlyUserNav();
    }
    else {
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { signOut }
)(NavigationHeader);
