import React from "react";
import Moment from "react-moment";
import { Icon, Container, Grid, Image, Loader } from "semantic-ui-react";

import { Link } from "react-router-dom";
import { STATIC_FILE_PATH } from "../applicationConstants";
import { getUserRoleText, fetchUserInfo } from "../utils/commonUtils";

export default class TopBar extends React.Component {
  state = {
    userInfo: null,
    isLoaded: false,
    systemStatus: null,
    date: null
  };

  timeInterval = null;
  unsubscribeStore = null;

  componentDidMount() {
    fetchUserInfo().then(response => {
      let jsDate = new Date(response.headers.date);
      // jsDate.setSeconds(jsDate.getSeconds() + 150);
      const userInfo = response.data;
      if (userInfo) {
        this.setState({ userInfo, date: jsDate });
      }
    });

    // this.unsubscribeStore = store.subscribe(() => {
    //   this.setState({
    //     auth: store.getState().auth,
    //     date: new Date()
    //     //systemStatus: this.state.systemStatus
    //   });
    // });

    this.timeInterval = window.setInterval(this.updateTimeStamp, 1000);
  }

  componentWillUnmount() {
    //    this.unsubscribeStore();
    window.clearInterval(this.timeInterval);
  }

  updateTimeStamp = () => {
    let { date } = this.state;
    if (date) {
      date.setSeconds(date.getSeconds() + 1);
      this.setState({ date });
    }
  };

  renderUserRole = userRole => {
    let roleText = getUserRoleText(userRole);

    roleText = `(${roleText})`;
    return roleText;
  };

  renderTopRightLoader = () => (
    <Grid.Column width={4} textAlign="center" verticalAlign="middle">
      <Loader active inline size="small" />
    </Grid.Column>
  );

  renderTopRightBar = () => {
    const { userInfo } = this.state;
    const { date } = this.state;
    return (
      <Grid.Column width={4}>
        <Icon name="user outline" /> <b>User ID</b> : {userInfo.userName} &nbsp;
        {this.renderUserRole(userInfo.roles[0])}
        <br />
        <Icon name="building outline" /> <b>Member Code</b> : {userInfo.memberId}
        <br />
        <Icon name="warehouse" /> <b>Branch</b> : {userInfo.branchId}
        <br />
        <Moment date={date} />
      </Grid.Column>
    );
  };

  render() {
    return (
      <Container>
        <Grid padded stackable>
          <Grid.Row>
            <Grid.Column width={12}>
              <Image
                src={`${STATIC_FILE_PATH}nse_ofs_logo_new.png`}
                size="medium"
                verticalAlign="middle"
                as={Link}
                to="/"
              />
            </Grid.Column>
            {!this.state.userInfo ? this.renderTopRightLoader() : this.renderTopRightBar()}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
