import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  Icon,
  Grid,
  Segment,
  Button,
  Table,
  Modal,
  Card,
  Label,
  Statistic,
  Divider,
  Popup,
  Placeholder,
  Loader,
  Dimmer,
} from "semantic-ui-react";

import DataFetchTimeStamp from "../utils/DataFetchTimeStamp";

import mastersApi from "../../apis/mastersApi";
import queryApi from "../../apis/queryApi";

export default class ActiveSecurities extends React.Component {
  columnsForDashboard = [
    {
      Header: "Details/MBP",
      Cell: (rowData) => (
        <Button.Group size="tiny">
          <Popup
            trigger={
              <Button onClick={() => this.setModalData(rowData)} circular icon="file alternate" />
            }
            content="View Security details"
            on="hover"
            position="left center"
          />

          <Popup
            trigger={
              <Button onClick={() => this.setMbpModalData(rowData)} circular icon="chart area" />
            }
            content="View Market by price"
            on="hover"
          />
        </Button.Group>
      ),
      width: 100,
    },
    {
      Header: "Symbol",
      id: "symbol",
      accessor: "symbol",
      width: 120,
    },
    {
      Header: "Security Name",
      accessor: "securityName",
    },
    {
      Header: "Series",
      accessor: "configuration.series",
      width: 50,
    },
    {
      Header: "Order Entry/Carry Forward",
      accessor: "configuration.series",
      Cell: (rowData) => {
        let series = rowData.original.configuration.series;
        let isNewOrderAllowed = rowData.original.configuration.isNewOrderAllowed;
        if (series === "IS" && !isNewOrderAllowed) {
          return "Carry Forward Orders";
        } else {
          return "Order Entry";
        }
      },
      width: 120,
    },
  ];

  columnsForFullView = [
    {
      Header: "Details/MBP",
      Cell: (rowData) => (
        <Button.Group size="tiny">
          <Popup
            trigger={
              <Button onClick={() => this.setModalData(rowData)} circular icon="file alternate" />
            }
            content="View Security details"
            on="hover"
          />

          <Popup
            trigger={
              <Button onClick={() => this.setMbpModalData(rowData)} circular icon="chart area" />
            }
            content="View Market by price"
            on="hover"
          />
        </Button.Group>
      ),
      width: 100,
    },
    {
      Header: "Symbol",
      id: "symbol",
      accessor: "symbol",
    },
    {
      Header: "Security Name",
      accessor: "securityName",
    },
    {
      Header: "Series",
      accessor: "configuration.series",
    },
    {
      Header: "Issue Size",
      accessor: "configuration.issueSize",
    },
    {
      Header: "Base Price",
      accessor: "configuration.basePrice",
    },
    {
      Header: "Date",
      accessor: "configuration.openOnDate",
      Cell: (rowData) => {
        return rowData.original.configuration.openOnDate.substring(0, 10);
      },
    },
    {
      Header: "Open Time",
      accessor: "configuration.mktOpenTime",
    },
    {
      Header: "Close Time",
      accessor: "configuration.mktCloseTime",
    },
    {
      Header: "Order Entry/Carry Forward",
      accessor: "configuration.series",
      Cell: (rowData) => {
        let series = rowData.original.configuration.series;
        let configLabel = rowData.original.configuration.configLabel;
        if (series === "IS" && configLabel === "T+1 day - IS") {
          return "Carry Forward Orders";
        } else return "Order Entry";
      },
      width: 120,
    },
  ];

  getColumnDef = () => {
    if (this.props.isDashboard) return this.columnsForDashboard;
    else return this.columnsForFullView;
  };

  constructor() {
    super();

    this.state = {
      tableData: null,
      tableDataCount: 0,
      isSecurityDetailOpen: false,
      isSecurityLoading: false,
      modalData: null,
      secDetailFetchTimeStamp: null,
      isMbpOpen: false,
      isMbpLoading: false,
      mbpData: null,
      mbpDataFetchTimeStamp: null,
      fetchTimeStamp: null,
    };
  }

  countType(symbol) {
    const countTypes = this.props.tableData.filter((security) => security.symbol === symbol);
    return countTypes.length;
  }

  componentDidMount() {
    this.fetchActiveSecurityData();
  }

  fetchActiveSecurityData = () => {
    this.setState({
      tableData: null,
      tableDataCount: 0,
    });
    mastersApi.get("/security/active").then((response) => {
      let fetchTimeStamp = new Date(response.headers.date);
      this.setState({
        tableData: response.data,
        tableDataCount: response.data.length,
        fetchTimeStamp,
      });
    });
  };

  handleClose = () => {
    this.setState({ isSecurityDetailOpen: false });
  };

  setModalData = (data) => {
    const { symbol } = data.original;
    const series = data.original.configuration.series;

    let newModalData = { ...data.original };
    this.setState({ isSecurityDetailOpen: true, isSecurityLoading: true });

    queryApi
      .get(`securitystats?symbol=${symbol}&series=${series}`)
      .then((response) => {
        const secDetailFetchTimeStamp = new Date(response.headers.date);
        newModalData.totalQuantity = response.data.totalQuantity;
        newModalData.totalPrice = response.data.totalPrice;
        newModalData.totalValue = response.data.totalValue;
        newModalData.totalRecords = response.data.totalRecords;
        this.setState({
          modalData: newModalData,
          isSecurityDetailOpen: true,
          isSecurityLoading: false,
          secDetailFetchTimeStamp,
        });
      })
      .catch((e) => {
        this.setState({
          modalData: null,
          isSecurityDetailOpen: false,
          isSecurityLoading: false,
        });
      });
  };

  renderModal = () => {
    const {
      modalData,
      isSecurityDetailOpen,
      isSecurityLoading,
      secDetailFetchTimeStamp,
    } = this.state;

    if (isSecurityDetailOpen && isSecurityLoading) {
      return (
        <Modal size="small" open={true} centered={false}>
          <Modal.Header>Security Details</Modal.Header>
          <Modal.Content>
            <Dimmer active={true}>
              <Loader active={true}>Fetching Security details... Please wait.</Loader>
            </Dimmer>
          </Modal.Content>
        </Modal>
      );
    }

    if (modalData) {
      return (
        <Modal
          size="small"
          onClose={this.handleClose}
          open={isSecurityDetailOpen}
          centered={false}
          closeIcon
        >
          <Modal.Header>
            Security Details
            <DataFetchTimeStamp fetchTimeStamp={secDetailFetchTimeStamp} />
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Grid stackable padded stretched>
                <Grid.Row>
                  <Grid.Column width="five">Symbol: {modalData.symbol}</Grid.Column>
                  <Grid.Column width="five">Series: {modalData.configuration.series}</Grid.Column>
                  <Grid.Column width="six">Isin Code: {modalData.isinCode}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="sixteen">Security Name: {modalData.securityName}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="five">Face Value: {modalData.faceValue}</Grid.Column>
                  <Grid.Column width="five">Tick Size: {modalData.tickSize}</Grid.Column>
                  <Grid.Column width="five">Lot Size: {modalData.regularLotSize}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="five">
                    Issue Size: {modalData.configuration.issueSize}
                  </Grid.Column>
                  <Grid.Column width="ten">
                    <nobr>
                      Base Price: <Icon name="rupee sign" color="violet" />
                      {modalData.configuration.basePrice}
                    </nobr>
                  </Grid.Column>
                </Grid.Row>
                {modalData.totalQuantity ? (
                  <React.Fragment>
                    <Grid.Row>
                      <Grid.Column width="sixteen">
                        <Divider horizontal section>
                          <Icon name="chart area" /> NSE Statistics
                        </Divider>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="five" textAlign="left">
                        <Statistic size="mini" color="violet">
                          <Statistic.Value>{modalData.totalQuantity}</Statistic.Value>
                          <Statistic.Label>Total Quantity</Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                      <Grid.Column width="six">
                        <Statistic size="mini" color="violet">
                          <Statistic.Value>
                            <Icon name="rupee" />
                            {modalData.totalValue}
                          </Statistic.Value>
                          <Statistic.Label>Total Value</Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                      <Grid.Column width="five">
                        <Statistic size="mini" color="violet">
                          <Statistic.Value>{modalData.totalRecords}</Statistic.Value>
                          <Statistic.Label>Total Orders</Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group size="mini">
              <Popup
                trigger={
                  <Button
                    color="violet"
                    onClick={this.handleClose}
                    content="Place Orders"
                    icon="gavel"
                    labelPosition="left"
                    as={Link}
                    to={`/order/entry/${modalData.symbol}/${modalData.configuration.series}`}
                  />
                }
                content={`Place orders for ${modalData.symbol}-${modalData.configuration.series}`}
                on="hover"
                position="bottom center"
                inverted
              />
              <Popup
                trigger={
                  <Button
                    color="orange"
                    onClick={this.handleClose}
                    content="View Orders"
                    icon="th list"
                    labelPosition="left"
                    as={Link}
                    to={`/order/report?symbol=${modalData.symbol}&series=${
                      modalData.configuration.series
                    }`}
                  />
                }
                content={`View orders for ${modalData.symbol}-${modalData.configuration.series}`}
                on="hover"
                position="bottom center"
                inverted
              />
              <Popup
                trigger={
                  <Button negative icon onClick={this.handleClose}>
                    <Icon name="close" />
                  </Button>
                }
                content="Close window"
                on="hover"
                position="bottom center"
              />
            </Button.Group>
          </Modal.Actions>
        </Modal>
      );
    }
    return null;
  };

  renderMbpModal = () => {
    const { mbpData, isMbpOpen, isMbpLoading, mbpDataFetchTimeStamp } = this.state;
    if (isMbpOpen && isMbpLoading) {
      return (
        <Modal size="small" open={true}>
          <Modal.Header as={Label} size="large">
            <Icon name="chart bar" />
            Market By Price
          </Modal.Header>
          <Modal.Content>
            <Dimmer active={true}>
              <Loader active={true}>Fetching MBP... Please wait.</Loader>
            </Dimmer>
          </Modal.Content>
        </Modal>
      );
    }
    if (mbpData) {
      return (
        <Modal size="small" onClose={this.handleMbpModalClose} open={isMbpOpen} closeIcon>
          <Modal.Header as={Label} size="large">
            <Icon name="chart bar" />
            Market By Price
            <DataFetchTimeStamp fetchTimeStamp={mbpDataFetchTimeStamp} />
          </Modal.Header>
          {mbpData && mbpData.length > 0 ? (
            <Table celled inverted selectable attached>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Symbol</Table.HeaderCell>
                  <Table.HeaderCell>Series</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Total Quantity</Table.HeaderCell>
                  <Table.HeaderCell>No. of Orders</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {mbpData.map((mbpRow) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{mbpRow.symbol}</Table.Cell>
                      <Table.Cell>{mbpRow.series}</Table.Cell>
                      <Table.Cell>{mbpRow.price}</Table.Cell>
                      <Table.Cell>{mbpRow.quantity}</Table.Cell>
                      <Table.Cell>{mbpRow.numberOfOrders}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          ) : (
            <Modal.Content>
              <Icon name="warning sign" />
              MBP data not found for this secuirity
            </Modal.Content>
          )}
        </Modal>
      );
    }
  };

  handleMbpModalClose = () => {
    this.setState({ isMbpOpen: false });
  };

  setMbpModalData = (data) => {
    const { symbol } = data.original;
    const series = data.original.configuration.series;

    this.setState({ isMbpOpen: true, isMbpLoading: true });
    queryApi
      .get(`marketByPrice?symbol=${symbol}&series=${series}`)
      .then((response) => {
        const mbpDataFetchTimeStamp = new Date(response.headers.date);
        this.setState({
          mbpData: response.data,
          isMbpOpen: true,
          isMbpLoading: false,
          mbpDataFetchTimeStamp,
        });
      })
      .catch((e) => {
        this.setState({ mbpData: null, isMbpOpen: false, isMbpLoading: false });
      });
  };

  render() {
    const { tableData, tableDataCount, fetchTimeStamp } = this.state;

    return (
      <Card fluid color="orange">
        <Card.Content>
          <Label as="a" color="orange" ribbon size="large">
            Securities
            <Label circular floating size="mini" color="black">
              {tableDataCount}
            </Label>
          </Label>
          <div style={{ float: "right" }}>
            <Popup
              trigger={
                <a href="javascript:;" onClick={this.fetchActiveSecurityData} size="mini">
                  <Icon name="refresh" />
                </a>
              }
              content="Refresh data"
              on="hover"
            />
            {this.props.isDashboard ? (
              <Popup
                trigger={
                  <Link to="/securities">
                    <Icon name="list" />
                  </Link>
                }
                content="Show Security Details"
                on="hover"
              />
            ) : (
              <Popup
                trigger={
                  <Link to="/">
                    <Icon name="dashboard" />
                  </Link>
                }
                content="Show Dashboard"
                on="hover"
              />
            )}
          </div>
          <br />
          <span style={{ float: "right" }}>
            <DataFetchTimeStamp fetchTimeStamp={fetchTimeStamp} />
          </span>
        </Card.Content>

        {this.renderModal()}
        {this.renderMbpModal()}
        {!tableData ? (
          <Segment padded>
            <Placeholder fluid>
              <Placeholder.Header>
                <Placeholder.Line length="full" />
              </Placeholder.Header>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder>
          </Segment>
        ) : tableData.length > 0 ? (
          <ReactTable
            data={tableData}
            columns={this.getColumnDef()}
            defaultPageSize={tableDataCount}
            showPagination={false}
            className="-striped -highlight"
            noDataText="No data available"
          />
        ) : (
          <Segment padded>Currently there are no securities available for bidding</Segment>
        )}
      </Card>
    );
  }
}
