import React from "react";

import * as Yup from "yup";

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Loader,
  Dimmer,
  Image,
  Divider,
  Popup
} from "semantic-ui-react";

import authApi from "../apis/auth";

import PasswordPolicy from "./PasswordPolicy";
import { STATIC_FILE_PATH } from "../applicationConstants";
import CryptoJS from "crypto-js";

const LoginFormHeader = ({ text }) => (
  <Grid stackable celled="internally">
    <Grid.Row>
      <Grid.Column width={6} textAlign="right">
        <Image
          src={`${process.env.PUBLIC_URL}/nse_ofs_logo_new.png`}
          size="medium"
          verticalAlign="middle"
        />
      </Grid.Column>
      <Grid.Column width={10} verticalAlign="middle" textAlign="left">
        <Header as="h3" color="orange">
          {text}
        </Header>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default class PasswordExpired extends React.Component {
  state = {
    passwordForm: {
      newPassword: "",
      confirmNewPassword: ""
    },
    interimToken: "",
    reasonCode: 0,
    confirmBox: null,
    isConfirmationOpen: false,
    isSubmitting: false,
    successMessage: null,
    isInfoModalOpen: false,
    passwordChangeSuccess: false,
    errors: {},
    touched: {},
    errorArray: [],
    isPasswordPolicyVisible: false
  };

  validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password cannot be blank")
      .min(12, "Password should have minimum 12 characters")
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{12,}$/,
        "Invalid Password format."
      ),
    confirmNewPassword: Yup.mixed()
      .test("confirmNewPassword", "Confirm Password cannot be blank.", function(
        confirmNewPassword
      ) {
        return !!confirmNewPassword;
      })
      .test("confirmNewPassword", "Passwords are not matching.", function(confirmNewPassword) {
        return this.parent.newPassword === confirmNewPassword;
      })
  });

  componentDidMount() {
    const { interimToken, reasonCode } = this.props.match.params;
    if (interimToken) {
      this.setState({ interimToken, reasonCode });
    }
  }

  /**
   * Form Data Handlers
   */
  handleInput = async (e, fieldProps) => {
    let value = fieldProps.value;
    let name = fieldProps.name;
    let currentState = { ...this.state };

    // Set data of changed field in copied state
    currentState.passwordForm[name] = value;
    currentState.touched[name] = true;

    // Validate the form
    currentState = this.validateForm(currentState, false);

    this.setState(currentState, () => console.log(this.state));
  };

  validateForm = (currentState, isValidateOnSubmit) => {
    try {
      const isValid = this.validationSchema.validateSync(currentState.passwordForm, {
        abortEarly: false
      });
      console.log(isValid);

      // Reset error object and array as all the validation checks have passed
      currentState.errors = {};
      currentState.errorArray = [];
    } catch (validationError) {
      console.log(validationError);

      // Set validation messages as per the field name
      let errors = {};
      let errorArray = [];

      validationError.inner.map(errorDetail => {
        if (!errors[errorDetail.path]) {
          errors[errorDetail.path] = [];
        }
        errors[errorDetail.path].push(errorDetail.message);

        // Add message to flat message array if the field has been touched
        if (currentState.touched[errorDetail.path] || isValidateOnSubmit)
          errorArray.push(errorDetail.message);
      });
      currentState.errors = errors;
      currentState.errorArray = errorArray;
    }

    return currentState;
  };

  handleFormSubmit = e => {
    e.preventDefault();

    // Validate form fields.
    let currentState = { ...this.state };
    currentState = this.validateForm(currentState, true);
    if (currentState.errorArray.length != 0) {
      this.setState({ errorArray: currentState.errorArray });
      return;
    }

    this.setState({ isSubmitting: true });


    let currentStateForApi = JSON.parse(JSON.stringify({ ...this.state }));

    const key  = CryptoJS.enc.Latin1.parse('1234567812345678');
    const iv   = CryptoJS.enc.Latin1.parse('1234567812345678');  
    
    //encrypting new password
    let dataNewPassword = this.state.passwordForm.newPassword;
    let encrypted1 = CryptoJS.AES.encrypt(dataNewPassword,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentStateForApi.passwordForm.newPassword = encrypted1;

    //encrypting confirm new password
    let dataConfirmNewPassword = this.state.passwordForm.confirmNewPassword;
    let encrypted2 = CryptoJS.AES.encrypt(dataConfirmNewPassword,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentStateForApi.passwordForm.confirmNewPassword = encrypted2;

    console.log("encyption completed");
    console.log(currentStateForApi.passwordForm);

    
    // Submit data if there are no validation failures
    authApi
      .post("/changePassword", currentStateForApi.passwordForm, {
        headers: { AUTHORIZATION: this.state.interimToken }
      })
      .then(response => {
        if (response.data && response.data.length > 0) {
          this.setState({
            isSubmitting: false,
            passwordChangeSuccess: false,
            errorArray: [...response.data]
          });
        } else {
          this.setState({ isSubmitting: false, passwordChangeSuccess: true });
        }
      })
      .catch(error => {
        let errorMessage = "";
        if (this.state.reasonCode === 2) {
          errorMessage = "This link has expired, please contact the exchange.";
        } else {
          errorMessage = "Password Change failed, try again later.";
        }

        this.setState({
          errorArray: [errorMessage],
          successMessage: null,
          confirmBox: null,
          isSubmitting: false
        });
      });
  };

  renderErrorSymmary = () => {
    const { errorArray } = this.state;

    if (!errorArray || errorArray.length == 0) return null;

    const errorMessage = errorArray[0];

    return (
      <Message error>
        <Icon name="warning sign" />
        {errorMessage}
      </Message>
    );
  };

  renderDefaultText = () => {
    const { reasonCode } = this.state;
    let messageText = "";
    if (reasonCode === "1") {
      messageText = "Your password has expired!";
    } else if (reasonCode === "2") {
      messageText = "Please set your password before logging in!";
    } else if (reasonCode === "3") {
      messageText = "Please set a new password";
    }

    return (
      <Message warning>
        <Message.Content>
          <Icon name="info circle" />
          {messageText}
        </Message.Content>
      </Message>
    );
  };

  render() {
    const {
      isSubmitting,
      errorArray,
      errors,
      touched,
      reasonCode,
      passwordChangeSuccess,
      isPasswordPolicyVisible
    } = this.state;
    const { newPassword, confirmNewPassword } = this.state.passwordForm;

    return (
      <React.Fragment>
        <Dimmer active={isSubmitting}>
          <Loader active={isSubmitting} content="Please wait.." />
        </Dimmer>

        <div
          className="expiry-form background-image"
          style={{
            background: `url(${STATIC_FILE_PATH}Building_Image_4_full_dimmed_compressed.jpg) no-repeat center center fixed`,
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover"
          }}
        >
          <style>{`
            body > div#root,
            body > div > div#app,
            body > div > div > div.expiry-form {
              height: 100%;
            }
          `}</style>

          <PasswordPolicy
            isVisible={isPasswordPolicyVisible}
            onClose={() => this.setState({ isPasswordPolicyVisible: false })}
          />
          <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle" relaxed>
            <Grid.Column style={{ maxWidth: 450 }}>
              {passwordChangeSuccess ? (
                <React.Fragment>
                  <Segment stacked>
                    <LoginFormHeader text="Change Password" />
                    <Divider />

                    <Button
                      color="orange"
                      fluid
                      size="large"
                      type="button"
                      onClick={() => this.props.history.push("/login")}
                    >
                      <Icon name="sign-in" /> Back to Login
                    </Button>
                    <Divider />
                  </Segment>
                  <Message success>
                    <Message.Content>
                      <Icon name="info circle" />
                      Password Changed successfully!
                    </Message.Content>
                  </Message>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form size="large" onSubmit={this.handleFormSubmit} className="error">
                    <Segment stacked>
                      <LoginFormHeader text="Change Password" />
                      <Divider />
                      <Popup
                        trigger={
                          <Form.Input
                            fluid
                            name="newPassword"
                            value={newPassword}
                            icon="lock"
                            iconPosition="left"
                            placeholder="New Password"
                            type="password"
                            autoComplete="off"
                            maxLength="30"
                            onChange={this.handleInput}
                          />
                        }
                        on="focus"
                        content="Enter New Password"
                        position="bottom right"
                        inverted
                        size="tiny"
                      />
                      <Popup
                        trigger={
                          <Form.Input
                            fluid
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            icon="lock"
                            iconPosition="left"
                            placeholder="Confirm New Password"
                            type="password"
                            autoComplete="off"
                            maxLength="30"
                            onChange={this.handleInput}
                          />
                        }
                        on="focus"
                        content="Confirm New Password"
                        position="bottom right"
                        inverted
                        size="tiny"
                      />
                      <Button color="orange" fluid size="large">
                        <Icon name="sign-in" /> Submit
                      </Button>

                      <div
                        style={{
                          textAlign: "right",
                          paddingTop: 10,
                          fontSize: 12,
                          fontWeight: "bold"
                        }}
                      >
                        <a
                          href="javascript:;"
                          onClick={() => this.setState({ isPasswordPolicyVisible: true })}
                          style={{ fontSize: 12 }}
                        >
                          <Icon name="lock" /> Password Policy
                        </a>
                      </div>
                    </Segment>
                  </Form>
                  {!touched.newPassword &&
                  !touched.confirmNewPassword &&
                  (!errorArray || errorArray.length === 0)
                    ? this.renderDefaultText()
                    : this.renderErrorSymmary()}
                </React.Fragment>
              )}
            </Grid.Column>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
