import React from "react";

import { Button, Icon, Modal } from "semantic-ui-react";

const ConfirmModal = props => {
  const { handleCancel, handleConfirm, isOpen, isSubmitting, header, text, loadingText } = props;
  return (
    <Modal size="tiny" onClose={handleCancel} open={isOpen} closeIcon>
      <Modal.Header>
        <Icon name="check circle" />
        {header}
      </Modal.Header>
      <Modal.Content>{isSubmitting ? <p>{loadingText} </p> : <p>{text}</p>}</Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            onClick={handleConfirm}
            content="Yes"
            icon="check"
            labelPosition="left"
            disabled={isSubmitting}
            loading={isSubmitting}
          />
          <Button.Or />
          <Button
            negative
            onClick={handleCancel}
            content="No"
            icon="close"
            labelPosition="right"
            disabled={isSubmitting}
          />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
