import React from "react";

import {
  Icon,
  Popup,
  Grid,
  Breadcrumb,
  Segment,
  Divider,
  Table,
  Tab,
  Menu
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import _ from "lodash";

import DataFetchTimeStamp from "../utils/DataFetchTimeStamp";
import PageLoader from "../utils/PageLoader";
import queryApi from "../../apis/queryApi";

export default class MOFReport extends React.Component {
  state = {
    isDashboard: true,
    loading: false,
    loaderText: null,
    mofSummaryData: null,
    summaryFetchTimeStamp: null,
    mofCategoryData: null,
    categoryFetchTimeStamp: null,
    visibleTabIndex: 0,
    visibleCategoryTabIndex: 0
  };

  componentDidMount() {
    console.log(this.props.isDashboard);
    this.setState({ isDashboard: this.props.isDashboard === undefined });
    this.fetchData();
  }

  fetchData = () => {
    this.fetchMofSummaryData();
    this.fetchCategoryData();
  };

  fetchMofSummaryData = () => {
    this.setState({ loading: true, loaderText: "Fetching summary details, please wait.." });
    queryApi
      .get("/get-mof-summary")
      .then(response => {
        const summaryFetchTimeStamp = new Date(response.headers.date);
        this.setState({
          loading: false,
          loaderText: null,
          mofSummaryData: response.data,
          summaryFetchTimeStamp
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchCategoryData = () => {
    this.setState({ loading: true, loaderText: "Fetching category details, please wait.." });
    queryApi
      .get("/get-mof-category-data")
      .then(response => {
        const categoryFetchTimeStamp = new Date(response.headers.date);
        let mofCategoryData = _.groupBy(response.data, "symbol");
        console.log(mofCategoryData);
        this.setState({
          loading: false,
          loaderText: null,
          mofCategoryData,
          categoryFetchTimeStamp
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderMofSummaryData = () => {
    const { mofSummaryData, summaryFetchTimeStamp } = this.state;

    if (!mofSummaryData) {
      return null;
    }

    if (mofSummaryData && mofSummaryData.length === 0) {
      return <Segment>Currently there is no data available</Segment>;
    }

    return (
      <Segment attached>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {summaryFetchTimeStamp ? (
                <DataFetchTimeStamp fetchTimeStamp={summaryFetchTimeStamp} />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="12">
              <Table celled selectable attached compact basic>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Symbol</Table.HeaderCell>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>No. of Bids</Table.HeaderCell>
                    <Table.HeaderCell>Total Bid Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Total Bid Value (in cr.)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {mofSummaryData.map((data, index) => {
                    let categoryText = "";
                    if (data.series === "IS") {
                      categoryText = "Non-retail";
                    } else if (data.series === "RS") {
                      categoryText = "Retail";
                    } else if(data.series === "ES"){
                      categoryText = "Employee";
                    }

                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{data.symbol}</Table.Cell>
                        <Table.Cell>{categoryText}</Table.Cell>
                        <Table.Cell  textAlign="right">{data.totalRecords}</Table.Cell>
                        <Table.Cell textAlign="right">{data.totalQuantity}</Table.Cell>
                        <Table.Cell textAlign="right">{data.totalValue.toFixed(4)}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  renderCategoryData = () => {
    const { mofCategoryData, categoryFetchTimeStamp } = this.state;

    if (!mofCategoryData) {
      return null;
    }

    if (mofCategoryData && mofCategoryData.length === 0) {
      return <Segment>Currently there is no data available</Segment>;
    }
    const symbols = _.keys(mofCategoryData);
    return (
      <Segment attached>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}></Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {categoryFetchTimeStamp ? (
                <DataFetchTimeStamp fetchTimeStamp={categoryFetchTimeStamp} />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="14">
              <Tab
                panes={symbols.map((symbol, index) => {
                  return {
                    menuItem: (
                      <Menu.Item
                        key={index}
                        onClick={() => this.setState({ visibleCategoryTabIndex: index })}
                      >
                        {<b>{symbol}</b>}
                      </Menu.Item>
                    ),
                    render: () => {
                      const symbolwiseCategoryData = mofCategoryData[symbol];
                      return (
                        <Table celled selectable attached compact basic>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={6}>Category</Table.HeaderCell>
                              <Table.HeaderCell>No. of Bids</Table.HeaderCell>
                              <Table.HeaderCell>Total Bid Quantity</Table.HeaderCell>
                              <Table.HeaderCell>Total Bid Value (in cr.)</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {symbolwiseCategoryData.map((data, index) => {
                              return (
                                <Table.Row key={index}>
                                  <Table.Cell>{data.panCategoryName}</Table.Cell>
                                  <Table.Cell textAlign="right">{data.totalRecords}</Table.Cell>
                                  <Table.Cell textAlign="right">{data.totalQuantity}</Table.Cell>
                                  <Table.Cell textAlign="right">{data.totalValue.toFixed(4)}</Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      );
                    }
                  };
                })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  renderPageLoader = () => {
    const { loading, loaderText } = this.state;
    if (loading) return <PageLoader loaderText={loaderText || "Please wait.."} />;
    else return null;
  };

  renderBreadcrumb = () => {
    if (!this.state.isDashboard)
      return (
        <Breadcrumb>
          <Breadcrumb.Section>
            <Link to="/">Home</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Utilities</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            MoF Report&nbsp;
            <Popup
              trigger={
                <a href="javascript:;">
                  <Icon name="help circle" color="blue" />
                </a>
              }
              content={`This utility is for periodic data dissemination to MoF.`}
              size="large"
              position="bottom center"
              inverted
            />
          </Breadcrumb.Section>
        </Breadcrumb>
      );
  };

  render() {
    return (
      <Segment color="orange">
        {this.renderPageLoader()}

        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>{this.renderBreadcrumb()}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>
          <h4>
            <Icon name="file alternate outline" /> MoF Report{" "}
            <Popup
              trigger={
                <a href="javascript:;" onClick={this.fetchData} size="mini">
                  <Icon name="refresh" />
                </a>
              }
              content="Refresh stats"
              on="hover"
            />
          </h4>
        </Divider>
        <br />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width="16">
              <Tab
                menu={{ pointing: true, color: "blue" }}
                panes={[
                  {
                    menuItem: (
                      <Menu.Item key={0} onClick={() => this.setState({ visibleTabIndex: 0 })}>
                        {<b>Summary</b>}
                      </Menu.Item>
                    ),
                    render: () => this.renderMofSummaryData()
                  },
                  {
                    menuItem: (
                      <Menu.Item key={1} onClick={() => this.setState({ visibleTabIndex: 1 })}>
                        {<b>Category wise Data</b>}
                      </Menu.Item>
                    ),
                    render: () => this.renderCategoryData()
                  }
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          {/* 
          <Grid.Row>
            <Grid.Column width="16">{this.renderMofSummaryData()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">{this.renderCategoryData()}</Grid.Column>
          </Grid.Row> */}
        </Grid>
      </Segment>
    );
  }
}
