import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { authenticateToken } from "../../actions";
import { isAuthorized } from "../../utils/uiAccessControl";

class ProtectedRoute extends React.Component {
  componentDidUpdate() {}

  render() {
    if (!isAuthorized(this.props.path, this.props.auth)) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location }
          }}
        />
      );
    }

    return <Route {...this.props} render={props => <this.props.component {...props} />} />;
  }
}

export default connect(
  state => ({
    auth: state.auth
  }),
  { authenticateToken }
)(ProtectedRoute);
