import React from "react";
import { Loader, Modal } from "semantic-ui-react";

const PageLoader = ({ loaderText }) => (
  <Modal basic size="small" open={true}>
    <Modal.Content>
      <Loader size="large" active inline="centered">
        {loaderText ? loaderText : "Loading.."}
      </Loader>
    </Modal.Content>
  </Modal>
);
export default PageLoader;
