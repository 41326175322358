import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import Home from "./Home";
import LoginPage from "./LoginPage";
import LdapLoginPage from "./LdapLoginPage";
import PasswordExpired from "./PasswordExpired";
import ForgotPassword from "./ForgotPassword";
import LoginRequiredRoute from "./utils/LoginRequiredRoute";

import "../semantic/dist/semantic.min.css";

class App extends React.Component {
  render() {
    return (
      <div id="app" style={{ backgroundColor: "#f2f2f2" }}>
        <Switch>
          <Route path="/login" exact component={() => <LoginPage />} />
          <Route path="/ldapLogin" exact component={() => <LdapLoginPage />} />
          <Route
            path="/password-expired/:interimToken/:reasonCode"
            exact
            component={PasswordExpired}
          />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <LoginRequiredRoute component={Home} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    isSignedIn: state.auth.isSignedIn
  }))(App)
);
/*export default App;*/
