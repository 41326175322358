import React from "react";
import { Link } from "react-router-dom";
import { Segment, Statistic, Icon, Grid, Popup, Divider, Placeholder } from "semantic-ui-react";
import ActiveSecurities from "./dashboard/ActiveSecurities";
import ActiveSecuritiesViewOnly from "./dashboard/ActiveSecuritiesViewOnly";

import OrderStats from "./dashboard/OrderStats";
import InterfaceConnectivityStatus from "./admin/InterfaceConnectivityStatus";
import DashLink from "./dashboard/DashLink";
import {
  isMemberSideRole,
  isControlUser,
  isViewOnlyUser,
  isLicUser,
  isMofUser,
  fetchUserInfo,
  isListcoUser
} from "../utils/commonUtils";

import DataFetchTimeStamp from "./utils/DataFetchTimeStamp";

import queryApi from "../apis/queryApi";
import auth from "../apis/auth";

import LICReport from "./admin/LICReport";

import MOFReport from "./admin/MOFReport";

import AllocationDashboard from "./allocation/AllocationDashboard";

export default class Dashboard extends React.Component {
  state = {
    orderStats: null,
    orderStatsFetchTimeStamp: null,
    userInfo: null,
    userRole: null,
    loginCount: null,
    loginCountFetchTimeStamp: null
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    fetchUserInfo().then(response => {
      console.log(response.headers);
      const userInfo = response.data;
      if (userInfo) {
        const userRole = userInfo.roles[0];
        this.setState({ userInfo, userRole }, this.fetchUserStats);
      }
    });
  }

  fetchUserStats = () => {
    this.setState({ orderStats: null, loginCount: null });
    const { userRole } = this.state;
    if (isMemberSideRole(userRole)) {
      queryApi.get("userstats").then(response => {
        let orderStatsFetchTimeStamp = new Date(response.headers.date);
        this.setState({ orderStats: response.data, orderStatsFetchTimeStamp });
      });
    } else {
      auth.get("/admin/loggedInCount").then(response => {
        let loginCountFetchTimeStamp = new Date(response.headers.date);
        this.setState({ loginCount: response.data, loginCountFetchTimeStamp });
      });
    }
  };

  renderDealerDashboard = () => {
    const { orderStats, orderStatsFetchTimeStamp } = this.state;

    return (
      <Grid columns={4} stackable padded>
        <Grid.Row>
          <Grid.Column width="16">
            <Divider horizontal>
              <h4>
                <Icon name="chart area" /> Order Summary{" "}
                <Popup
                  trigger={
                    <a href="javascript:;" onClick={this.fetchUserStats} size="mini">
                      <Icon name="refresh" />
                    </a>
                  }
                  content="Refresh stats"
                  on="hover"
                />
              </h4>
            </Divider>

            <DataFetchTimeStamp fetchTimeStamp={orderStatsFetchTimeStamp} />
          </Grid.Column>
        </Grid.Row>
        {orderStats ? (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column width="three">
                <OrderStats
                  type="Submitted"
                  count={orderStats.SUBMITTED}
                  icon="gavel"
                  color="blue"
                  to="/order/report"
                />
              </Grid.Column>
              <Grid.Column width="three">
                <OrderStats
                  type="Confirmed"
                  count={orderStats.CONFIRMED}
                  icon="check circle"
                  color="green"
                  to="/order/report?orderStatus=Confirmed"
                />
              </Grid.Column>
              <Grid.Column width="three">
                <OrderStats
                  type="Cancelled"
                  count={orderStats.CANCELLED}
                  icon="user close"
                  color="red"
                  to="/order/report?orderStatus=Cancelled"
                />
              </Grid.Column>
              <Grid.Column width="3">
                <OrderStats
                  type="Rejected"
                  count={orderStats.REJECTED}
                  icon="close"
                  color="red"
                  to="/order/errororders"
                />
              </Grid.Column>
              <Grid.Column width="2">
                <DashLink
                  text={
                    <span>
                      View <br /> All
                    </span>
                  }
                  icon="angle right"
                  color="violet"
                  link="/order/report"
                />
              </Grid.Column>
              <Grid.Column width="2">
                <DashLink text="Place Order" icon="save" color="violet" link="/order/entry" />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ) : (
          // <PageLoader loaderText="Fetching dashboard statistics.." />

          <Grid.Row>
            <Grid.Column width="three">
              <Segment color="orange" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="three">
              <Segment color="orange" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="three">
              <Segment color="orange" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="three">
              <Segment color="orange" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="2">
              <DashLink
                text={
                  <span>
                    View <br /> All
                  </span>
                }
                icon="angle right"
                color="violet"
                link="/order/report"
              />
            </Grid.Column>
            <Grid.Column width="2">
              <DashLink text="Place Order" icon="save" color="violet" link="/order/entry" />
            </Grid.Column>
          </Grid.Row>
        )}
        <Divider />
        <Grid.Row>
          <Grid.Column width="eight">
            <ActiveSecurities isDashboard={true} />
          </Grid.Column>
          <Grid.Column width="2">
            {/* <DashLink text="Bulk Upload" icon="upload" color="violet" link="/order/bulkupload" /> */}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    );
  };

  renderExchangeDashboard = () => {
    const { loginCount, loginCountFetchTimeStamp } = this.state;
    return (
      <Grid columns={5} stackable padded>
        <Grid.Row>
          <Grid.Column width="eight">
            <ActiveSecurities isDashboard={true} />
          </Grid.Column>
          <Grid.Column width="5">
            <InterfaceConnectivityStatus />
          </Grid.Column>
          <Grid.Column width="3">
            {loginCount ? (
              <Segment color="violet" textAlign="center" secondary raised>
                <div>
                  <div style={{ float: "right" }}>
                    <Popup
                      trigger={
                        <a href="javascript:;" onClick={this.fetchUserStats} size="mini">
                          <Icon name="refresh" />
                        </a>
                      }
                      content="Refresh stats"
                      on="hover"
                    />
                  </div>
                </div>
                <br />
                <Statistic size="small" as={Link} to="/utils/active-users">
                  <Statistic.Value>
                    <Icon name="users" color="violet" size="small" />
                  </Statistic.Value>
                  <br />
                  <Statistic.Label>Active Users</Statistic.Label>
                  <Statistic.Label>{loginCount}</Statistic.Label>
                  <br />
                  <DataFetchTimeStamp fetchTimeStamp={loginCountFetchTimeStamp} />
                </Statistic>
              </Segment>
            ) : (
              <Segment color="violet" textAlign="center" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                    <Placeholder.Line length="short" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderViewOnlyDashboard = () => {
    return (
      <Grid columns={5} stackable padded>
        <Grid.Row>
          <Grid.Column width="eight">
            <ActiveSecuritiesViewOnly isDashboard={true} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    const { userRole } = this.state;

    if (isMemberSideRole(userRole)) {
      return this.renderDealerDashboard();
    } else if (isControlUser(userRole)) {
      return this.renderExchangeDashboard();
    } else if (isLicUser(userRole)) {
      return <LICReport />;
    } else if (isMofUser(userRole)) {
      return <MOFReport />;
    } else if (isListcoUser(userRole)) {
      return <AllocationDashboard />;
    } else if(isViewOnlyUser(userRole)) {
      return this.renderViewOnlyDashboard();
    }

    return null;
  }
}
