/**
 * 
 * This file contains constants for this applications
 * Example to use these constants in any components 
 import {CONSTANT_NAME} from 'applicationConstants'
 * 
 * 
 **/

require("dotenv").config();

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL === undefined
    ? "http://localhost:8080/api"
    : process.env.REACT_APP_API_BASE_URL;
export const AUTH_BASE_URL =
  process.env.REACT_APP_AUTH_BASE_URL || "http://localhost:8080/api/auth";
export const STATIC_FILE_PATH = process.env.REACT_APP_STATIC_FILE_PATH || "./";
