import React from "react";

import {
  Icon,
  Popup,
  Grid,
  Breadcrumb,
  Segment,
  Divider,
  Form,
  Button,
  Input,
  Table,
  Modal
} from "semantic-ui-react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";

import DataFetchTimeStamp from "../utils/DataFetchTimeStamp";
import PageLoader from "../utils/PageLoader";
import queryApi from "../../apis/queryApi";
import { downloadAsTextFile, fetchUserInfo, isLicUser } from "../../utils/commonUtils";

export default class LICReport extends React.Component {
  state = {
    userInfo: null,
    isDashboard: true,
    isLicUserFlag: false,
    clientPan: null,
    loading: false,
    loaderText: null,
    summaryData: null,
    biddingDetails: null,
    isDetatilsModalOpen: false,
    biddingDetailsFetchTimeStamp: null,
    summaryFetchTimeStamp: null
  };

  componentDidMount() {
    this.setState({
      loading: true,
      loaderText: "Please wait..."
    });

    fetchUserInfo()
      .then(resp => {
        this.setState(
          {
            userInfo: resp.data,
            isLicUserFlag: isLicUser(resp.data.roles[0]),
            isDashboard: this.props.isDashboard === undefined,
            loading: false,
            loaderText: null
          },
          this.getSummaryDataByRole
        );
      })
      .catch(err => {
        this.setState({
          userInfo: null,
          isDashboard: this.props.isDashboard === undefined,
          loading: false,
          loaderText: null
        });
      });
  }

  /**
   * Form Data Handlers
   */

  handleInput = (e, fieldProps) => {
    let value = fieldProps.value;

    if (value) {
      value = value.toUpperCase();
    }

    this.setState({ clientPan: value });
  };

  /**
   * Fetch summary data only if LIC user is viewing this screen
   */
  getSummaryDataByRole = () => {
    const { isLicUserFlag } = this.state;
    if (isLicUserFlag) {
      this.getSummaryData();
    }
  };

  getSummaryData = () => {
    this.setState({ loading: true, loaderText: "Fetching summary details, please wait.." });
    const { clientPan } = this.state;
    queryApi
      .get(`/get-client-summary?clientPan=${clientPan}`)
      .then(response => {
        console.log(response.data);
        const summaryFetchTimeStamp = new Date(response.headers.date);
        this.setState({
          loading: false,
          loaderText: null,
          summaryData: response.data,
          summaryFetchTimeStamp
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  getBiddingDetails = symbol => {
    this.setState({ loading: true, loaderText: "Fetching Bidding details, please wait.." });
    const { clientPan } = this.state;
    queryApi
      .get(`/get-client-data?clientPan=${clientPan}&symbol=${symbol}`)
      .then(response => {
        console.log(response.data);
        const biddingDetailsFetchTimeStamp = new Date(response.headers.date);
        this.setState({
          loading: false,
          loaderText: null,
          biddingDetails: response.data,
          isDetatilsModalOpen: true,
          biddingDetailsFetchTimeStamp
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          loaderText: null,
          biddingDetails: null,
          isDetatilsModalOpen: false
        });
      });
  };

  closeBiddingDetails = () => {
    this.setState({
      biddingDetails: null,
      isDetatilsModalOpen: false
    });
  };

  handleExportToCsv = biddingDetails => {
    this.setState({ loading: true, loaderText: "Exporting bidding details, please wait.." });
    const { clientPan } = this.state;
    const symbol = biddingDetails[0].symbol;
    queryApi
      .get(`/export-client-data?clientPan=${clientPan}&symbol=${symbol}`)
      .then(response => {
        console.log(response.data);
        this.setState({
          loading: false,
          loaderText: null
        });
        const fileName = `BID_DETAILS_${clientPan}_${symbol}.csv`;
        downloadAsTextFile(fileName, response.data);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          loaderText: null
        });
      });
  };

  renderPageLoader = () => {
    const { loading, loaderText } = this.state;
    if (loading) return <PageLoader loaderText={loaderText || "Please wait.."} />;
    else return null;
  };

  renderSummaryData = () => {
    const { summaryData, summaryFetchTimeStamp, clientPan, userInfo, isLicUserFlag } = this.state;

    if (!summaryData) {
      return null;
    }

    if (summaryData && summaryData.length === 0) {
      return (
        <Segment color="violet">
          {isLicUserFlag ? (
            <span>No orders available.</span>
          ) : (
            <span>
              No orders available for PAN: <b>{clientPan}</b>
            </span>
          )}
        </Segment>
      );
    }

    return (
      <Segment color="violet" attached>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <b>Summary Data</b>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {summaryFetchTimeStamp ? (
                <DataFetchTimeStamp fetchTimeStamp={summaryFetchTimeStamp} />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <Table celled selectable attached compact basic>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Symbol</Table.HeaderCell>
                    <Table.HeaderCell>No. of Bids</Table.HeaderCell>
                    <Table.HeaderCell>Total Bid Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Total Bid Value (in cr.)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {summaryData.map((data, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Popup
                            trigger={
                              <a
                                href="javascript:;"
                                onClick={() => this.getBiddingDetails(data.symbol)}
                              >
                                <Icon name="external alternate" />
                              </a>
                            }
                            content="View Bidding Details"
                            size="tiny"
                            position="left center"
                            inverted
                          />
                        </Table.Cell>
                        <Table.Cell>{data.symbol}</Table.Cell>
                        <Table.Cell>{data.totalRecords}</Table.Cell>
                        <Table.Cell>{data.totalQuantity}</Table.Cell>
                        <Table.Cell>{data.totalValue}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  renderBiddingDetails = () => {
    const { biddingDetails, isDetatilsModalOpen, biddingDetailsFetchTimeStamp } = this.state;
    if (!isDetatilsModalOpen) {
      return null;
    }
    return (
      <Modal
        size="large"
        onClose={this.closeBiddingDetails}
        open={isDetatilsModalOpen}
        centered={false}
        closeIcon
      >
        <Modal.Header>Bidding details</Modal.Header>
        <ReactTable
          columns={[
            {
              Header: "Sr. No.",
              id: "row",
              width: 60,
              filterable: false,
              Cell: row => {
                return <div style={{ textAlign: "center" }}>{row.index + 1}</div>;
              }
            },
            {
              Header: "TM Name",
              accessor: "tradingMemberName",
              width: 200
            },
            {
              Header: "Order no.",
              accessor: "orderId",
              width: 180
            },
            {
              Header: "Bid Quantity",
              accessor: "quantity",
              width: 100
            },
            {
              Header: "Bid Price",
              accessor: "price",
              width: 100
            },
            {
              Header: "Bid Value (Rs.)",
              accessor: "value"
            },
            {
              Header: "Margin Type",
              accessor: "marginType",
              Cell: rowData => {
                if (rowData.original.marginType === "0") {
                  return "0%";
                } else if (rowData.original.marginType === "1") {
                  return "100%";
                }
              },
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    value={filter ? filter.value : ""}
                    onChange={event => onChange(event.target.value)}
                  >
                    <option value="">Select Margin</option>
                    <option value="0">0%</option>
                    <option value="1">100%</option>
                  </select>
                );
              }
            },
            {
              Header: "Custodian Status",
              accessor: "isCpApproved",
              Cell: rowData => {
                if (rowData.original.isCpApproved) {
                  return "Confirmed";
                } else {
                  return "Pending";
                }
              },
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    value={filter ? filter.value : ""}
                    onChange={event => onChange(event.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value={false}>Pending</option>
                    <option value={true}>Confirmed</option>
                  </select>
                );
              }
            }
          ]}
          data={biddingDetails}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          noDataText="No data available"
        />

        <Modal.Actions>
          <div style={{ float: "left" }}>
            <DataFetchTimeStamp fetchTimeStamp={biddingDetailsFetchTimeStamp} />
          </div>
          <Button.Group size="mini">
            <Popup
              trigger={
                <Button icon="download" onClick={() => this.handleExportToCsv(biddingDetails)} />
              }
              content="Export to CSV"
              on="hover"
              inverted
              size="tiny"
            />
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  };

  renderBreadcrumb = () => {
    if (!this.state.isDashboard)
      return (
        <Breadcrumb>
          <Breadcrumb.Section>
            <Link to="/">Home</Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Utilities</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            Client Report&nbsp;
            <Popup
              trigger={
                <a href="javascript:;">
                  <Icon name="help circle" color="blue" />
                </a>
              }
              content={`This utility is for periodic data dissemination to LIC.`}
              size="large"
              position="bottom center"
              inverted
            />
          </Breadcrumb.Section>
        </Breadcrumb>
      );
  };

  render() {
    const { clientPan, isLicUserFlag } = this.state;
    return (
      <Segment color="orange">
        {this.renderPageLoader()}
        {this.renderBiddingDetails()}

        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>{this.renderBreadcrumb()}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>
          <h4>
            <Icon name="file alternate outline" /> Client Report{" "}
            {isLicUserFlag ? (
              <Popup
                trigger={
                  <a href="javascript:;" onClick={this.getSummaryData} size="mini">
                    <Icon name="refresh" />
                  </a>
                }
                content="Refresh data"
                on="hover"
              />
            ) : null}
          </h4>
        </Divider>
        <br />
        <Form error onSubmit={this.handleFormSubmitClick}>
          <Grid stackable>
            {isLicUserFlag ? null : (
              <Grid.Row>
                <Grid.Column width="3">
                  <Input
                    label="Client PAN"
                    action={
                      <Button
                        icon="search"
                        onClick={this.getSummaryData}
                        disabled={!clientPan || clientPan.length != 10}
                      />
                    }
                    labelPosition="left"
                    placeholder="Enter Client PAN"
                    name="clientPan"
                    value={clientPan || ""}
                    onChange={this.handleInput}
                    autoComplete="off"
                    size="mini"
                    maxLength="10"
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width="16">{this.renderSummaryData()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}
