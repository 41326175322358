import {
  SIGN_IN,
  SIGN_OUT,
  AUTHENTICATE_USER_TOKEN,
  REFRESH_USER_TOKEN,
  UPDATE_TOKEN_IN_STATE,
  CLEAR_TOKEN_IN_STATE
} from "../actions/types";
import Cookies from "universal-cookie";

const INITIAL_STATE = {
  isSignedIn: null,
  isIncorrectCredentials: null,
  accessToken: null,
  otpToken: null,
  userInfo: null
};

const COOKIE_IDENTITY = "IDENTITY";
const COOKIE_EXP_TIME = "EXP_TIME";
const COOKIE_BROWSER_SIGNATURE = "BS";

const clearCookies = cookies => {
  cookies.remove(COOKIE_IDENTITY, { path: "/" });
  cookies.remove(COOKIE_EXP_TIME, { path: "/" });
  cookies.remove(COOKIE_BROWSER_SIGNATURE, { path: "/" });
};

export default (state = INITIAL_STATE, action) => {
  const cookies = new Cookies();
  const {
    authorized,
    expired,
    accessToken,
    refreshedToken,
    userInfo,
    valid,
    messages,
    expiryTime
  } = action.payload || {};

  switch (action.type) {
    case SIGN_IN:
      if (!accessToken || messages.length > 0) {
        return {
          ...state,
          isSignedIn: false,
          isIncorrectCredentials: true,
          accessToken: null
        };
      } else {
        // if (process.env.NODE_ENV === "development") {
        //   cookies.set(COOKIE_IDENTITY, accessToken, { path: "/" });
        // }
        cookies.set(COOKIE_EXP_TIME, Date.now() + expiryTime, {
          path: "/"
        });

        return {
          ...state,
          isSignedIn: true,
          isIncorrectCredentials: false,
          accessToken: accessToken
        };
      }

    case AUTHENTICATE_USER_TOKEN:
      if (authorized && valid && !expired) {
        return {
          ...state,
          isSignedIn: true,
          isIncorrectCredentials: false,
          accessToken: accessToken,
          userInfo: userInfo
        };
      } /*
      // LOGIC TO UPDATE REFRESH TOKEN IN COOKIE AND REDUX STORE,
      // This is commented. Remove this when login module is stable.
      else if (expired && refreshedToken) {
        console.log("IN expired && refreshedToken");
        cookies.set(COOKIE_IDENTITY, refreshedToken, { path: "/" });
        return {
          ...state,
          isSignedIn: true,
          isIncorrectCredentials: false,
          accessToken: refreshedToken
        };
      }*/ else {
        // cookies.remove(COOKIE_IDENTITY, { path: "/" });
        clearCookies(cookies);
        return {
          ...state,
          isSignedIn: false,
          isIncorrectCredentials: false,
          accessToken: null
        };
      }

    case REFRESH_USER_TOKEN:
      if (accessToken) {
        // cookies.set(COOKIE_IDENTITY, accessToken, { path: "/" });
        cookies.set(COOKIE_EXP_TIME, Date.now() + expiryTime, {
          path: "/"
        });
        return {
          ...state,
          accessToken: accessToken
        };
      } else {
        // cookies.remove(COOKIE_IDENTITY, { path: "/" });
        clearCookies(cookies);
        return {
          ...state,
          isSignedIn: false,
          isIncorrectCredentials: false,
          accessToken: null
        };
      }

    case CLEAR_TOKEN_IN_STATE:
    case SIGN_OUT:
      // cookies.remove(COOKIE_IDENTITY, { path: "/" });
      clearCookies(cookies);
      return { ...state, isSignedIn: false, accessToken: null };

    case UPDATE_TOKEN_IN_STATE:
      cookies.set("IDENTITY", action.payload, { path: "/" });
      return { ...state, accessToken: action.payload };

    default:
      return state;
  }
};
