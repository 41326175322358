import React from "react";
import _ from "lodash";
import { Segment, Button, Icon, Container, Accordion, Popup } from "semantic-ui-react";
import { API_BASE_URL } from "../../applicationConstants";
import store from "../../reducers/store";

import messageStreamingApi from "../../apis/messageStreamingApi";

import {
  isViewOnlyUser
} from "../../utils/commonUtils";
import { useEffect } from "react";

export default class MessageWindow extends React.Component {
  interval = null;
  lastMessageElementRef = null;
  MAX_AUTO_RECONNECT_ATTEMPTS = 200;

  constructor() {
    super();

    this.state = {
      messages: [],
      allMessages: [],
      isVisible: false,
      isMaximized: false,
      windowMaxHeight: 150,
      maxNumberOfMessages: 200,
      messageFontSize: 12,
      auth: null,
      lastMessageTimestamp: null,
      marketStatus: null,
      isAutoReconnectActive: true,
      reconnectAttempts: 0,
      isLoading: false
    };



    store.subscribe(() => {
      this.setState({
        auth: store.getState().auth
      });
    });


  }

  componentDidMount() {
    
    this.initialMessageLoad();
   // this.msgLoad5Sec();
    // this.setupEventSource();
  }
  // msgLoad5Sec = () => {
  //   this.initialMessageLoad();
  // }

  componentWillUnmount() {
   
    console.log("Message window unmounted.. closing event stream");
    if (this.source) this.source.close();
  }


  initialMessageLoad = () => {

 // setInterval(() => {console.log('Interval triggered');}, 5000);
  const token =  localStorage.getItem('token');

    const { maxNumberOfMessages } = this.state;
    
    this.setState({ isLoading: true });

    messageStreamingApi
      .get("/get-messages")
      .then(response => {
        console.log(response.data,"Response of get-messages>>>>>>>>>>>>>>>");
        if (response.data && response.data.length > 0) {
          let messageArray = response.data;
          // Filter out MBP messages
          // if (this.interval === null) {
          //   this.startInterval();
          // }
          if (messageArray && messageArray.length > 0) {
            messageArray = _.filter(messageArray, function (o) {
              return o.type !== "MBP";
            });
          }
          let marketStatus = null;
          let lastMsgTimestamp = null;
          for (let i = 0; i < messageArray.length; i++) {
            const message = messageArray[i];
            if (message.type === "MKT_OPEN") {
              marketStatus = "Open";
            } else if (message.type === "MKT_CLOSE") {
              marketStatus = "Close";
            }
            lastMsgTimestamp = message.createDate;
          }

          console.log("Market Status : ", marketStatus);

          if (marketStatus == null) {
            marketStatus = "Close";
          }

          let sliceArr = messageArray.slice(
            messageArray.length - maxNumberOfMessages,
            messageArray.length
          );

          // Update state
          this.setState(
            {
              messages: sliceArr,
              allMessages: messageArray,
              lastMessageTimestamp: lastMsgTimestamp,
              marketStatus,
              isLoading: false
            },
            this.scrollToBottom
          );
          console.log(marketStatus);
          console.log(lastMsgTimestamp);
        }

        // Setup event source after initial message loading is completed
        this.setupEventSource();
      })
      .catch(error => {
        // Update state
        this.setState({ isLoading: false });
        // Setup event source after initial message loading is completed
        this.setupEventSource();
      });
  };

  setupEventSource = () => {
    console.log("Setup Event source");

    const { maxNumberOfMessages, lastMessageTimestamp } = this.state;
    const userId =  localStorage.getItem('userId');
    const token =  localStorage.getItem('token');
    
    let apiUrl = "";
    if (lastMessageTimestamp) {
      //apiUrl = `/messaging/message-stream?startTime=${lastMessageTimestamp}`;
      apiUrl = `/messaging/message-stream`;
    } else {
     // apiUrl = "/messaging/message-stream";
     apiUrl = `/messaging/message-stream`;
    }

    this.source = new EventSource(`${API_BASE_URL}${apiUrl}`, {
      withCredentials: true
    });

    this.source.onerror = error => {
      // this.setState({ messages: [], allMessages: [] });
      console.log(error);
      this.source.close();

      const { isAutoReconnectActive, reconnectAttempts } = this.state;

      // If Auto Reconnect is enabled
      // then reconnect and check if MAX_AUTO_RECONNECT_ATTEMPTS is reached
      // If yes then disable Auto Reconnect and reset reconnect attempt counter
      // else increment reset reconnect attempt counter
      if (isAutoReconnectActive) {
        // Reconnect
        this.setupEventSource();

        if (reconnectAttempts + 1 >= this.MAX_AUTO_RECONNECT_ATTEMPTS) {
          console.log("MAX_AUTO_RECONNECT_ATTEMPTS reached.. disabling auto reconnect");
          this.setState({ isAutoReconnectActive: false, reconnectAttempts: 0 });
        } else {
          console.log("Increment reset reconnect attempt counter");
          this.setState({ reconnectAttempts: reconnectAttempts + 1 });
        }
      }
    };

    this.source.addEventListener("message", message => {
      const { messages, allMessages } = this.state;

      console.log("Message Market Status", this.state.marketStatus);

      let marketStatus = this.state.marketStatus || "Close";

      // Received message
      var messageObject = JSON.parse(message.data);

      // Skip heart beat and MBP messages
      if (messageObject.type === "HEARTBEAT" || messageObject.type === "MBP") {
        return;
      } else if (messageObject.type === "MKT_OPEN") {
        marketStatus = "Open";
      } else if (messageObject.type === "MKT_CLOSE") {
        marketStatus = "Close";
      }

      console.log("HeartBeat marke",marketStatus);
      let sliceArr = messages.slice(messages.length - maxNumberOfMessages, messages.length);

      // Copy all message array and add new data.
      let allMessagesCpy = [...allMessages];
      let messagesCpy = [...sliceArr];

      const lastReceivedMessage = allMessages[allMessages.length - 1];
      let lastMsgTimestamp = null;
      if (lastReceivedMessage) lastMsgTimestamp = lastReceivedMessage.createDate;

      allMessagesCpy.push(messageObject);
      messagesCpy.push(messageObject);

      console.log("market status : ", marketStatus);

      // Update state
      this.setState(
        {
          messages: messagesCpy,
          allMessages: allMessagesCpy,
          lastMessageTimestamp: lastMsgTimestamp,
          marketStatus
        },
        this.scrollToBottom
      );
    });
  };

  manualReconnect = () => {
    this.setState({ isAutoReconnectActive: true, reconnectAttempts: 0 });
    this.source.close();

    // Reconnect
    this.setupEventSource();
  };

  scrollToBottom = () => {
    // if (this.lastMessageElementRef) this.lastMessageElementRef.scrollIntoView();
  };

  download = () => {
    const filename = "Message_area.txt";

    // Parse messages present in react state to a text
    let text = "";
    const { allMessages } = this.state;
    if (!allMessages || allMessages.length === 0) return false;

    allMessages.map(message => {
      text = text + message.messageText + "\n";
    });

    // Create HTML element and download the text
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  toggleMessageWindow = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible });
  };

  maximizeMessageWindow = () => {
    this.setState({
      isVisible: true,
      isMaximized: true,
      windowMaxHeight: 350
    });
  };

  minimizeMessageWindow = () => {
    this.setState({
      isVisible: true,
      isMaximized: false,
      windowMaxHeight: 150
    });
  };

  renderWindowMinizeMaximizeButtons = () => {
    const { isVisible, isMaximized } = this.state;
    if (!isVisible) return;

    if (isMaximized) {
      return (
        <Popup
          trigger={
            <Button
              icon="window restore outline"
              size="mini"
              color="linkedin"
              onClick={this.minimizeMessageWindow}
            />
          }
          content="Minimize message area"
          on="hover"
          size="mini"
          inverted
        />
      );
    } else {
      return (
        <Popup
          trigger={
            <Button
              icon="window maximize outline"
              size="mini"
              color="grey"
              onClick={this.maximizeMessageWindow}
            />
          }
          content="Expand"
          on="hover"
          size="mini"
          inverted
        />
      );
    }
  };

  renderShowHideButton = () => {
    const { isVisible } = this.state;

    if (isVisible) {
      return (
        <Popup
          trigger={<Button icon="hide" color="grey" onClick={this.toggleMessageWindow} />}
          content="Hide"
          on="hover"
          size="mini"
          inverted
        />
      );
    } else {
      return (
        <Popup
          trigger={<Button icon="eye" color="linkedin" onClick={this.toggleMessageWindow} />}
          content="Show"
          on="hover"
          size="mini"
          inverted
        />
      );
    }
  };

  renderZoomInOut = () => {
    const { isVisible, messageFontSize } = this.state;
    if (!isVisible) return null;

    return (
      <React.Fragment>
        <Popup
          trigger={
            <Button
              icon="zoom-in"
              color="grey"
              onClick={() => {
                let newMessageFontSize = messageFontSize + 2;

                if (newMessageFontSize >= 22) return;

                this.setState({
                  messageFontSize: newMessageFontSize
                });
              }}
            />
          }
          content="Increase Font size"
          on="hover"
          size="mini"
          inverted
        />
        <Popup
          trigger={
            <Button
              icon="zoom-out"
              color="grey"
              onClick={() => {
                let newMessageFontSize = messageFontSize - 2;

                if (newMessageFontSize <= 8) return;

                this.setState({
                  messageFontSize: newMessageFontSize
                });
              }}
            />
          }
          content="Decrease Font size"
          on="hover"
          size="mini"
          inverted
        />
      </React.Fragment>
    );
  };

  renderManualReconnectButton = () => {
    const { isAutoReconnectActive } = this.state;
    if (!isAutoReconnectActive) {
      return (
        <Popup
          trigger={
            <Button
              negative
              size="mini"
              content="Disconnected"
              labelPosition="right"
              icon="refresh"
              type="button"
              onClick={this.manualReconnect}
            />
          }
          content={
            <span>
              Message window is currently disconnected and it will not receive latest updates from
              the host.
              <br />
              Click here to attempt manually reconnection.
              <br />
              <br />
              Please check internet connectivity and your login session if you are not able to
              reconnect. Kindly contact the exchange if this issue persists.
            </span>
          }
          on="hover"
          size="mini"
          inverted
        />
      );
    }
    return null;
  };

  renderMarketStatus = () => {
    const { marketStatus } = this.state;
    console.log(marketStatus, "Current Market Status")
    let content = null;
    let color = null;
    if (marketStatus === "Open") {
      content = "Open";
      color = "green";
    } else if (marketStatus === "Close") {
      content = "Close";
      color = "red";
    }
    else {
      return (
        //   // <Label color={color} size="medium">
        //   //   Market Status
        //   //   <Label.Detail>
        //   //     <Loader inline active size="mini" />
        //   //   </Label.Detail>
        //   // </Label>
        <Button.Group size="mini">
          <Button content="Market Status" type="button" />
          <Button loading type="button" disabled />
        </Button.Group>
      );

    }

    return (
      // <Label color={color} size="medium">
      //   Market Status
      //   <Label.Detail>{content}</Label.Detail>
      // </Label>
      <Button.Group size="mini">
        <Button color={color} content="Market Status" type="button" />
        <Button color={color} content={content} type="button" disabled />
      </Button.Group>
    );
    // return (
    //   <Button.Group size="mini">
    //     <Button
    //       content={content}
    //       color={color}
    //       label={{
    //         as: "a",
    //         basic: true,
    //         pointing: "right",
    //         content: "Market Status",
    //         href: "javascript:;",
    //         color: "grey"
    //       }}
    //       labelPosition="left"
    //       type="button"
    //       size="mini"
    //     />
    //   </Button.Group>
    // );
  };

  render() {
    const { auth } = this.state;
    const { isVisible, messages, windowMaxHeight, messageFontSize, isLoading } = this.state;

    let isGeneralUser = true;

    if (auth != null) {
      const userRole = auth.userInfo.roles[0];

      if (isViewOnlyUser(userRole)) {
        console.log("true view only");
        isGeneralUser = false;
      }
    }

    return (
      <Container>
        <Segment>
          <Accordion>
            <Accordion.Title active={isVisible} index={0}>
              <Icon name="dropdown" onClick={this.toggleMessageWindow} />
              <b onClick={this.toggleMessageWindow}>Message Window</b>
              <div style={{ float: "right", paddingBottom: "5px" }}>
                {this.renderManualReconnectButton()}
                &nbsp;&nbsp;&nbsp;
                {this.renderMarketStatus()}
                &nbsp;&nbsp;&nbsp;
                <Button.Group size="mini">
                  {this.renderWindowMinizeMaximizeButtons()}
                  {this.renderZoomInOut()}
                  {this.renderShowHideButton()}
                  {isGeneralUser ?
                    <Popup
                      trigger={
                        <Button
                          icon="download"
                          size="mini"
                          color="orange"
                          onClick={() => this.download()}
                        />
                      }
                      content="Download Message Area"
                      on="hover"
                      size="mini"
                      inverted
                    /> : null
                  }
                </Button.Group>
              </div>
            </Accordion.Title>
            <Accordion.Content active={isVisible}>
              <Segment inverted style={{ overflow: "auto", maxHeight: windowMaxHeight }} attached>
                {messages && messages.length > 0 ? (
                  messages.map(message => {
                    if (!message.messageText) return;
                    return (
                      <span
                        style={{
                          fontSize: messageFontSize,
                          fontFamily: "consolas",
                          whiteSpace: "pre-wrap"
                        }}
                        key={message.id}
                        ref={el => {
                          this.lastMessageElementRef = el;
                        }}
                      >
                        {message.messageText}
                        <br />
                      </span>
                    );
                  })
                ) : (
                    <span>
                      {"No Messages received yet"}
                      <br />
                    </span>
                  )}
              </Segment>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Container>
    );
  }
}
