import store from "../reducers/store";
import { signOut } from "../actions";
import { STATIC_FILE_PATH } from "../applicationConstants";

export const applyInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(
    function(response) {
      // LOGIC TO UPDATE REFRESH TOKEN IN COOKIE AND REDUX STORE,
      // This is commented. Remove this when login module is stable
      //   const refreshedToken = response.headers["authorization"];
      //   if (refreshedToken) {
      //     console.log("calling authenticateToken");
      //     store.dispatch(updateTokenInState(refreshedToken));
      //   }
      return response;
    },
    function(error) {
      if (error.response && 401 === error.response.status) {
        console.log("401 detected, logging out user");
        store.dispatch(signOut());

        // window.location = STATIC_FILE_PATH;
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
