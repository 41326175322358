import React from "react";
import Moment from "react-moment";
import ReactNotifications from "react-browser-notifications";
import { Icon, Button, Popup, Modal } from "semantic-ui-react";
import Cookies from "universal-cookie";

import { refreshToken, clearTokenInState } from "../../actions";
import store from "../../reducers/store";

// Five mins. (300000 mS) before session time-out, user will get warning
const WARN_TIME = 300000;

export default class SessionExpiryNotification extends React.Component {
  state = {
    tag: 0,
    isSessionExpiryNotificationOpen: false,
    expiryTime: null,
    timeLeft: null
  };

  timeLeftInterval = null;
  browserNotificationTimer = null;

  componentDidMount() {
    Notification.requestPermission();
    const cookies = new Cookies();
    const expiryTime = parseInt(cookies.get("EXP_TIME"));
    this.setTimings(expiryTime);
  }

  componentWillUnmount() {
    window.clearInterval(this.timeLeftInterval);
    window.clearTimeout(this.browserNotificationTimer);
  }

  setTimings = expiryTime => {
    const warnTime = expiryTime - WARN_TIME;
    this.setState({ expiryTime, warnTime });

    // If current time is before expiry time and warn time,
    // Then set the timer to view notification.
    if (expiryTime > Date.now() && warnTime > Date.now()) {
      this.browserNotificationTimer = window.setTimeout(
        this.showBrowserNotifications,
        warnTime - Date.now()
      );
    }
  };

  setTimeLeft = () => {
    const { expiryTime, timeLeft } = this.state;
    this.setState({ timeLeft: expiryTime - Date.now() });
  };

  showBrowserNotifications = () => {
    // If the Notifications API is supported by the browser
    // then show the notification
    const { tag } = this.state;
    this.setState({ tag: tag + 1, isSessionExpiryNotificationOpen: true });

    // Updates time left in state.
    this.timeLeftInterval = window.setInterval(this.setTimeLeft, 1000);
    if (this && this.n && this.n.supported()) this.n.show();
  };

  handleNotificationClick = event => {
    console.log("Notification Clicked");
    // Show OFS tab and close the notification
    window.focus();
    if (this && this.n) this.n.close(event.target.tag);
  };

  handleExtendSession = () => {
    this.setState({ isSessionExpiryNotificationOpen: false, timeLeft: null });
    window.clearInterval(this.timeLeftInterval);
    store.dispatch(refreshToken());

    this.browserNotificationTimer = window.setTimeout(() => {
      const cookies = new Cookies();
      const expiryTime = parseInt(cookies.get("EXP_TIME"));
      this.setTimings(expiryTime);
    }, 5000);
  };

  handleLoginAgain = () => {
    store.dispatch(clearTokenInState());
  };

  renderTimeLeft = () => {
    const { timeLeft } = this.state;
    if (timeLeft > 0) {
      var minutes = Math.floor(timeLeft / 60000);
      var seconds = ((timeLeft % 60000) / 1000).toFixed(0);
      return (
        "Time left: " +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    } else {
      return null;
    }
  };

  render() {
    const { tag, isSessionExpiryNotificationOpen, timeLeft } = this.state;
    return (
      <React.Fragment>
        <Modal size="tiny" open={isSessionExpiryNotificationOpen}>
          <Modal.Header>
            <Icon name="warning circle" />
            Session Expiry Notification
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {timeLeft > 0 || timeLeft === null ? (
                <span>
                  Your current session in <b>NSE eOFS</b> system is about to <b>expire</b>!
                  <br />
                  Do you want to extend the session?
                  <br />
                  <br />
                  {this.renderTimeLeft()}
                </span>
              ) : (
                <span>
                  Your session has expired, please login again.
                  <br />
                  <br />
                </span>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {timeLeft > 0 || timeLeft === null ? (
              <Button.Group size="mini">
                <Popup
                  trigger={
                    <Button icon="check" onClick={() => this.handleExtendSession()} content="Yes" />
                  }
                  content="Continue with the session"
                  on="hover"
                  inverted
                  size="tiny"
                />
                <Popup
                  trigger={
                    <Button
                      negative
                      icon="close"
                      content="No"
                      onClick={() =>
                        this.setState({ isSessionExpiryNotificationOpen: false, timeLeft: null })
                      }
                    />
                  }
                  content="End the session"
                  on="hover"
                  inverted
                  size="tiny"
                />
              </Button.Group>
            ) : (
              <Button.Group size="mini">
                <Popup
                  trigger={
                    <Button
                      positive
                      icon="sign-in"
                      onClick={() => this.handleLoginAgain()}
                      content="Login again"
                    />
                  }
                  content="Go to login page"
                  on="hover"
                  inverted
                  size="tiny"
                />
              </Button.Group>
            )}
          </Modal.Actions>
        </Modal>

        <ReactNotifications
          onRef={ref => (this.n = ref)}
          title="NSE eOFS"
          body="Your session in NSE eOFS system is about to end, click here and extend your session."
          icon="favicon.png"
          tag={`${tag}`}
          timeout="30000"
          onClick={event => this.handleNotificationClick(event)}
        />
      </React.Fragment>
    );
  }
}
