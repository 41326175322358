import axios from "axios";
import { applyInterceptor } from "./interceptor";
import { API_BASE_URL } from "../applicationConstants";

export default applyInterceptor(
  axios.create({
    baseURL: `${API_BASE_URL}/masters`,
    withCredentials: true
  })
);
