import React from "react";
import Moment from "react-moment";

const DataFetchTimeStamp = props => (
  <span style={{ fontSize: 10, float: props.float || "right", color: "grey" }}>
    Updated:&nbsp;
    <Moment date={props.fetchTimeStamp || new Date()} format="YYYY-MM-DD HH:mm:ss" />
  </span>
);

export default DataFetchTimeStamp;
