import React from "react";
import { Input } from "semantic-ui-react";
import authApi from "../apis/auth";
import fileDownload from "js-file-download";

/**
 * Generic function to export react table data to a csv file and download it to client's browser
 */
export const exportReactTableToCsv = (columnDef, tableData, fileName) => {
  let csvText = "";
  let jsonDataModel = [];
  let headers = [];
  columnDef.forEach(column => {
    if (column.Cell) return;

    headers.push(column.Header);
    jsonDataModel.push(column.accessor);
  });

  // Set Header row for CSV
  csvText = headers.join(",") + "\n";

  tableData.map(row => {
    jsonDataModel.map((jsonKey, index) => {
      const columnData = row[jsonKey] || "";
      console.log(columnData);

      if (index === jsonDataModel.length - 1) csvText = csvText + columnData;
      else csvText = csvText + columnData + ",";
    });
    csvText = csvText + "\n";
  });

  downloadAsTextFile(fileName, csvText);
  return false;
};

export const downloadAsTextFile = (fileName, fileText) => {
  fileDownload(fileText, fileName);

  // Create HTML element and download the text
  // var element = document.createElement("a");
  // element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(fileText));
  // element.setAttribute("download", fileName);
  // element.style.display = "none";
  // document.body.appendChild(element);
  // element.click();
  // document.body.removeChild(element);
};

/**
 * User roles
 * */
export const ROLE_PREFIX = "ROLE_";
export const USER_TYPE_INQUIRY_VAL = 3;
export const USER_TYPE_DEALER_VAL = 0;
export const USER_TYPE_BRANCH_MANAGER_VAL = 5;
export const USER_TYPE_CORPORATE_MANAGER_VAL = 4;
export const USER_TYPE_CONTROL_USER1_VAL = 1;
export const USER_TYPE_CONTROL_USER2_VAL = 2;
export const USER_TYPE_VIEW_ONLY_VAL = 10;
export const USER_TYPE_LIC_VAL = 6;
export const USER_TYPE_MOF_VAL = 7;
export const USER_TYPE_LISCO_VAL = 8;

export const USER_TYPE_INQUIRY = `${ROLE_PREFIX}${USER_TYPE_INQUIRY_VAL}`;
export const USER_TYPE_DEALER = `${ROLE_PREFIX}${USER_TYPE_DEALER_VAL}`;
export const USER_TYPE_BRANCH_MANAGER = `${ROLE_PREFIX}${USER_TYPE_BRANCH_MANAGER_VAL}`;
export const USER_TYPE_CORPORATE_MANAGER = `${ROLE_PREFIX}${USER_TYPE_CORPORATE_MANAGER_VAL}`;
export const USER_TYPE_CONTROL_USER1 = `${ROLE_PREFIX}${USER_TYPE_CONTROL_USER1_VAL}`;
export const USER_TYPE_VIEW_ONLY_USER = `${ROLE_PREFIX}${USER_TYPE_VIEW_ONLY_VAL}`;
export const USER_TYPE_CONTROL_USER2 = `${ROLE_PREFIX}${USER_TYPE_CONTROL_USER2_VAL}`;
export const USER_TYPE_LIC = `${ROLE_PREFIX}${USER_TYPE_LIC_VAL}`;
export const USER_TYPE_MOF = `${ROLE_PREFIX}${USER_TYPE_MOF_VAL}`;
export const USER_TYPE_LISCO = `${ROLE_PREFIX}${USER_TYPE_LISCO_VAL}`;

export const MEMBER_SIDE_ROLES = [
  USER_TYPE_DEALER,
  USER_TYPE_BRANCH_MANAGER,
  USER_TYPE_CORPORATE_MANAGER
];

export const getRoleHierarchy = forRoleId => {
  const roleHierarchy = {
    [USER_TYPE_CONTROL_USER1]: [
      {
        roleKey: USER_TYPE_DEALER,
        roleName: getUserRoleText(USER_TYPE_DEALER),
        roleId: USER_TYPE_DEALER_VAL
      },
      {
        roleKey: USER_TYPE_CORPORATE_MANAGER,
        roleName: getUserRoleText(USER_TYPE_CORPORATE_MANAGER),
        roleId: USER_TYPE_CORPORATE_MANAGER_VAL
      },
      {
        roleKey: USER_TYPE_BRANCH_MANAGER,
        roleName: getUserRoleText(USER_TYPE_BRANCH_MANAGER),
        roleId: USER_TYPE_BRANCH_MANAGER_VAL
      },
      {
        roleKey: USER_TYPE_LIC,
        roleName: getUserRoleText(USER_TYPE_LIC),
        roleId: USER_TYPE_LIC_VAL
      },
      {
        roleKey: USER_TYPE_MOF,
        roleName: getUserRoleText(USER_TYPE_MOF),
        roleId: USER_TYPE_MOF_VAL
      }
    ],
    [USER_TYPE_VIEW_ONLY_USER]: [
      {
        roleKey: USER_TYPE_DEALER,
        roleName: getUserRoleText(USER_TYPE_DEALER),
        roleId: USER_TYPE_DEALER_VAL
      },
      {
        roleKey: USER_TYPE_CORPORATE_MANAGER,
        roleName: getUserRoleText(USER_TYPE_CORPORATE_MANAGER),
        roleId: USER_TYPE_CORPORATE_MANAGER_VAL
      },
      {
        roleKey: USER_TYPE_BRANCH_MANAGER,
        roleName: getUserRoleText(USER_TYPE_BRANCH_MANAGER),
        roleId: USER_TYPE_BRANCH_MANAGER_VAL
      },
      {
        roleKey: USER_TYPE_LIC,
        roleName: getUserRoleText(USER_TYPE_LIC),
        roleId: USER_TYPE_LIC_VAL
      },
      {
        roleKey: USER_TYPE_MOF,
        roleName: getUserRoleText(USER_TYPE_MOF),
        roleId: USER_TYPE_MOF_VAL
      }
    ],
    [USER_TYPE_CORPORATE_MANAGER]: [
      {
        roleKey: USER_TYPE_DEALER,
        roleName: getUserRoleText(USER_TYPE_DEALER),
        roleId: USER_TYPE_DEALER_VAL
      },
      {
        roleKey: USER_TYPE_BRANCH_MANAGER,
        roleName: getUserRoleText(USER_TYPE_BRANCH_MANAGER),
        roleId: USER_TYPE_BRANCH_MANAGER_VAL
      }
    ],
    [USER_TYPE_BRANCH_MANAGER]: [
      {
        roleKey: USER_TYPE_DEALER,
        roleName: getUserRoleText(USER_TYPE_DEALER),
        roleId: USER_TYPE_BRANCH_MANAGER_VAL
      }
    ]
  };

  if (!forRoleId) return roleHierarchy;
  else return !roleHierarchy[forRoleId] ? [] : roleHierarchy[forRoleId];
};

/**
 * Convert role ID to role display text for displaying on UI
 * */
export const getUserRoleText = userRole => {
  let roleText = "";
  switch (userRole) {
    case USER_TYPE_DEALER:
      roleText = "Dealer";
      break;
    case USER_TYPE_CONTROL_USER1:
      roleText = "Control User";
      break;
    case USER_TYPE_CORPORATE_MANAGER:
      roleText = "Member Admin";
      break;
    case USER_TYPE_BRANCH_MANAGER:
      roleText = "Branch Admin";
      break;
    case USER_TYPE_LIC:
      roleText = "Client";
      break;
    case USER_TYPE_MOF:
      roleText = "MoF";
      break;
    case USER_TYPE_LISCO:
      roleText = "LISTCO";
      break;
    case USER_TYPE_VIEW_ONLY_USER:
      roleText = "VIEWONLY";
  }
  return roleText;
};

export const isDealer = userRole => {
  return userRole === USER_TYPE_DEALER;
};

export const isCorporateManager = userRole => {
  return userRole === USER_TYPE_CORPORATE_MANAGER;
};

export const isBranchManager = userRole => {
  return userRole === USER_TYPE_BRANCH_MANAGER;
};

export const isMemberSideRole = userRole => {
  return (
    userRole === USER_TYPE_DEALER ||
    userRole === USER_TYPE_BRANCH_MANAGER ||
    userRole === USER_TYPE_CORPORATE_MANAGER
  );
};

export const isControlUser = userRole => {
  return userRole === USER_TYPE_CONTROL_USER1;
};

export const isViewOnlyUser = userRole => {
  return userRole === USER_TYPE_VIEW_ONLY_USER;
};

export const isLicUser = userRole => {
  return userRole === USER_TYPE_LIC;
};

export const isMofUser = userRole => {
  return userRole === USER_TYPE_MOF;
};

export const isListcoUser = userRole => {
  return userRole === USER_TYPE_LISCO;
};

export const isInquiryUser = userRole => {
  return userRole === USER_TYPE_INQUIRY;
};

export const isLdapExchangeUserRole = (userRole) => {
  return isControlUser(userRole) || isListcoUser(userRole);
}

export const isLdapExchangeUserType = (userType) => {
  const role = `${ROLE_PREFIX}${userType}`;
  return isLdapExchangeUserRole(role);
}

export const generateBrowserSignature = window => {
  try {
    let browserSignature = (function(window, screen, navigator) {
      // https://github.com/darkskyapp/string-hash
      function checksum(str) {
        let hash = 5381,
          i = str.length;

        while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

        return hash >>> 0;
      }

      // http://stackoverflow.com/a/4167870/1250044
      function map(arr, fn) {
        let i = 0,
          len = arr.length,
          ret = [];
        while (i < len) {
          ret[i] = fn(arr[i++]);
        }
        return ret;
      }

      return checksum(
        [
          navigator.userAgent,
          [screen.height, screen.width, screen.colorDepth].join("x"),
          new Date().getTimezoneOffset(),
          !!window.sessionStorage,
          !!window.localStorage,
          map(navigator.plugins, function(plugin) {
            return [
              plugin.name,
              plugin.description,
              map(plugin, function(mime) {
                return [mime.type, mime.suffixes].join("~");
              }).join(",")
            ].join("::");
          }).join(";")
        ].join("###")
      );
    })(this, window.screen, window.navigator);
    console.log(browserSignature);
    return browserSignature;
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const fetchUserInfo = () => {
  return authApi.get("/user-info");
};

export const mandatoryLabel = props => {
  return (
    <label>
      {props.text} &nbsp;<b style={{ color: "red" }}>*</b>
    </label>
  );
};
