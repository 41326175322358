import {
  SIGN_IN,
  SIGN_OUT,
  AUTHENTICATE_USER_TOKEN,
  REFRESH_USER_TOKEN,
  UPDATE_TOKEN_IN_STATE,
  CLEAR_TOKEN_IN_STATE
} from "./types";
import authApi from "../apis/auth";

/**
 * This Action handles the request from login page
 * This will post the login form data to authentication server
 * After that it will dispatch SIGN_IN action using the response data received from the server as payload
 */
export const signIn = authResponse => {
  console.log(authResponse);
  return {
    type: SIGN_IN,
    payload: authResponse
  };
};

export const authenticateToken = token => async dispatch => {
  console.log("in authenticateToken");
  let responseData = null;
  let isError = false;
  // If environment is dev/local check if token is present
  // if (process.env.NODE_ENV === "development" && !token) {
  //   dispatch({ type: AUTHENTICATE_USER_TOKEN, payload: {} });
  //   return;
  // }

  // If environment is dev/local token will be sent in request params
  // else it will be sent in request cookie
  const validateTokenUrl = "/validateToken";
  // process.env.NODE_ENV === "development" ? `/validateToken?token=${token}` : "/validateToken";

  let response = null;

  response = await authApi.post(validateTokenUrl, null).catch(error => {
    if (error.response) responseData = error.response.data;
    else responseData = {};
    isError = true;
  });

  // Reattempt in case of any error or empty response body
  if (isError || !response.data) {
    response = await authApi.post(validateTokenUrl, null).catch(error => {
      if (error.response) responseData = error.response.data;
      else responseData = {};
      isError = true;
    });
  }

  console.log(responseData);
  if (response) {
    dispatch({
      type: AUTHENTICATE_USER_TOKEN,
      payload: response.data
    });
  } else {
    dispatch({
      type: AUTHENTICATE_USER_TOKEN,
      payload: responseData
    });
  }
};

export const refreshToken = () => async dispatch => {
  let responseData = null;
  const response = await authApi.post("/refreshCookie", null).catch(error => {
    if (error.response) responseData = error.response.data;
    else responseData = {};
  });
  console.log(responseData);
  if (response) {
    dispatch({
      type: REFRESH_USER_TOKEN,
      payload: response.data
    });
  } else {
    dispatch({
      type: REFRESH_USER_TOKEN,
      payload: responseData
    });
  }
};

export const updateTokenInState = token => {
  return {
    type: UPDATE_TOKEN_IN_STATE,
    payload: token
  };
};

export const clearTokenInState = () => {
  return {
    type: CLEAR_TOKEN_IN_STATE,
    payload: null
  };
};

export const signIn1 = () => {
  return {
    type: SIGN_IN
  };
};

export const signOut = token => async dispatch => {
  let responseData = null;
  const response = await authApi
    .get("/logout", {
      headers: {
        AUTHORIZATION: token
      }
    })
    .catch(error => {
      if (error.response) responseData = error.response.data;
      else responseData = {};
    });

  if (response) {
    dispatch({
      type: SIGN_OUT,
      payload: response.data
    });
  } else {
    dispatch({
      type: SIGN_OUT,
      payload: responseData
    });
  }
};