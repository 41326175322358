import React from "react";

import { Input } from "semantic-ui-react";

const FilterInput = ({ filterProps }) => (
  <Input
    fluid
    onKeyPress={event => {
      if (event.keyCode === 13 || event.which === 13) {
        filterProps.onChange(event.target.value);
        return;
      }
    }}
    onChange={event => {
      if (!event.target.value) {
        filterProps.onChange(event.target.value);
        return;
      }
    }}
  />
);

export default FilterInput;
