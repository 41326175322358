import React from "react";
import { Segment, Icon, Statistic } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class DashLink extends React.Component {
  render() {
    return (
      <Segment color={this.props.color} textAlign="center" secondary raised>
        <Statistic size="small" as={Link} to={this.props.link}>
          <Statistic.Value>
            <Icon name={this.props.icon} color={this.props.color} size="small" />
          </Statistic.Value>
          <Statistic.Label>{this.props.text}</Statistic.Label>
        </Statistic>
      </Segment>
    );
  }
}
