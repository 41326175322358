import React from "react";

import { Button, Icon, Modal, Form, Message, Input, Confirm } from "semantic-ui-react";
import mastersApi from "../apis/mastersApi";
import PasswordPolicy from "./PasswordPolicy";
import CryptoJS from "crypto-js";

class ChangePassword extends React.Component {
  state = {
    data: [],
    passwordForm: { oldPassword: "", newPassword: "", confirmNewPassword: "" },
    errors: {},
    touched: {},
    successMessage: null,
    msgArray: null,
    success: false,
    isSubmitting: false,
    open: false
  };

  handleChangePasswordModalClose = () => {
    //window.location.reload();
    this.setState({
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      successMessage: null,
      msgArray: null,
      success: false,
      open: false,
      isPasswordPolicyVisible: false
    });
    this.props.onClose();
    return false;
  };

  handlePasswordChange = () => {
    // Form submission logic
    console.log(this.state);

    // Validate form fields.
    let currentState = { ...this.state };
    this.setState({ isSubmitting: true });

    let currentStateForApi = JSON.parse(JSON.stringify({ ...this.state }));

    //encrypting old password
    let data = this.state.passwordForm.oldPassword;
    const key  = CryptoJS.enc.Latin1.parse('1234567812345678');
    const iv   = CryptoJS.enc.Latin1.parse('1234567812345678');  
    let encrypted = CryptoJS.AES.encrypt(data,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentStateForApi.passwordForm.oldPassword = encrypted;

    //encrypting new password
    let data1 = this.state.passwordForm.newPassword;
    let encrypted1 = CryptoJS.AES.encrypt(data1,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentStateForApi.passwordForm.newPassword = encrypted1;

    //encrypting confirm new password
    let data2 = this.state.passwordForm.confirmNewPassword;
    let encrypted2 = CryptoJS.AES.encrypt(data2,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentStateForApi.passwordForm.confirmNewPassword = encrypted2;

    console.log("password in encrypted format:");
    console.log(currentStateForApi.passwordForm);

    // Submit data if there are no validation failures
    mastersApi
      .post("/changePassword", currentStateForApi.passwordForm)
      .then(response => {
        console.log(response);
        if (response.data) {
          let messageArray = [];
          if (response.data && response.data.length > 0) {
            // TODO set messages from API in state
            response.data.map(message => {
              messageArray.push(message);
            });
            this.setState({
              msgArray: messageArray,
              successMessage: null,
              isSubmitting: false,
              success: false
            });
          } else {
            //let messages = response.data;
            // if (response.data === "") {
            this.setState({
              msgArray: [],
              successMessage: "Password Changed Successfully!",
              passwordForm: {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: ""
              },
              isSubmitting: false,
              success: true
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
        let messageArray = ["Password Change Falied!"];
        this.setState({
          errorArray: messageArray,
          successMessage: null,
          isSubmitting: false,
          success: false
        });
      });
  };

  renderErrorMessage = () => {
    const { msgArray } = this.state;

    if (!msgArray || msgArray.length == 0) return null;

    return (
      <Message error>
        Following validation checks have failed:
        <ul>
          {msgArray.map(message => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </Message>
    );
  };

  renderSuccessMessage = () => {
    const { successMessage } = this.state;

    if (successMessage) {
      return (
        <Message success onDismiss={() => this.setState({ msgArray: null })}>
          {successMessage}
        </Message>
      );
    } else {
      return null;
    }
  };

  handleInput = (e, fieldProps) => {
    let value = fieldProps.value;
    let name = fieldProps.name;

    let currentState = { ...this.state };

    // Set data of changed field in copied state
    currentState.passwordForm[name] = value;

    this.setState(currentState);
  };

  render() {
    const { passwordForm, touched, errors, success, isPasswordPolicyVisible } = this.state;
    const { isChangePasswordModalOpen, onClose } = this.props;

    console.log(this.state);
    return (
      <Modal size="tiny" open={isChangePasswordModalOpen}>
        <Modal.Header>
          <Icon name="lock" />
          Change Password
        </Modal.Header>
        <Modal.Content>
          <PasswordPolicy
            isVisible={isPasswordPolicyVisible}
            onClose={() => this.setState({ isPasswordPolicyVisible: false })}
          />
          <Form error onSubmit={this.handleFormSubmitClick}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Current Password"
                placeholder="Current Password"
                name="oldPassword"
                type="password"
                value={passwordForm.oldPassword}
                onChange={this.handleInput}
                autoComplete="off"
                className={touched.symbol && errors.symbol ? "error" : ""}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="New Password"
                placeholder="New Password"
                name="newPassword"
                type="password"
                value={passwordForm.newPassword}
                onChange={this.handleInput}
                autoComplete="off"
                className={touched.symbol && errors.symbol ? "error" : ""}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Re-Enter Password"
                placeholder="Re-Enter Password"
                name="confirmNewPassword"
                type="password"
                value={passwordForm.confirmNewPassword}
                onChange={this.handleInput}
                autoComplete="off"
                className={touched.symbol && errors.symbol ? "error" : ""}
              />
            </Form.Group>
          </Form>
          <div style={{ textAlign: "right" }}>
            <a
              href="javascript:;"
              onClick={() => this.setState({ isPasswordPolicyVisible: true })}
              style={{ fontSize: 12 }}
            >
              <Icon name="lock" /> Password Policy
            </a>
          </div>

          {success ? this.renderSuccessMessage() : this.renderErrorMessage()}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              negative
              onClick={this.handlePasswordChange}
              content="Submit"
              icon="check"
              labelPosition="left"
            />
            <Button.Or />
            <Button
              onClick={this.handleChangePasswordModalClose}
              content="No"
              icon="close"
              labelPosition="right"
            />
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ChangePassword;
