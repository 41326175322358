import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Form,
  Segment,
  Button,
  Divider,
  Icon,
  Grid,
  Message,
  Progress,
  Breadcrumb,
  Popup,
  Dimmer,
  Loader,
  GridColumn
} from "semantic-ui-react";
import schedulerApi from "../../apis/schedulerApi";
import FileInput from "../utils/FileInput";
import { fetchUserInfo } from "../../utils/commonUtils";
import ConfirmModal from "../utils/ConfirmModal";

export default class DemandFileUpload extends React.Component {
    
    state = {
    fileType: null,
    isFileSelected: false,
    file: undefined,
    fileName: null,
    isUploading: false,
    isFileUploadAllowed: true,
    isFileUploadComplete: false,
    errorArray: [],
    successMessage: null,
    
    userInfo: null,
    isUploading: false,
    loaderText: null,
    isConfirmationOpen: false,
      };
fileTypeConfigurations = {
    BSE: {
      uploadUrl: "/demanddata/bsefileupload"
    },
    MSE: {
        uploadUrl: "/demanddata/msefileupload"
      }
  };
  componentDidMount() {
    this.setState({ isUploading: true, loaderText: "Loading..." });

    // Setup user info
    fetchUserInfo()
      .then(response => {
        const userInfo = response.data;

        this.setState({
          userInfo,
          isUploading: false,
          loaderText: null
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isUploading: false, loaderText: null });
      });
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  
handleFileSelect = file => {
    
    this.setState({
        isFileSelected: true,
        file,
        fileName: file.name,
      });
}
handleFormSubmitClick = () => {
    this.setState({ isConfirmationOpen: true, successMessage: null });
 };

  handleFormSubmit = () => {
    const { file, fileType } = this.state;
    if (!file || !fileType) {
      return false;
    }

    let filePostUrl = this.fileTypeConfigurations[fileType].uploadUrl;

    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    this.setState({ isFileSelected: false, isUploading: true });

    // Post data
    schedulerApi
      .post(filePostUrl, formData, config)
      .then(response => {
        this.handleServerResonse(response.data);
        this.handleReset();
      })
      .catch(error => {
        console.log(error);
        this.setState({ isUploading: false });
      });
  };
  
  handleServerResonse = response => {
    if (response && response.length > 0) {
      let errorArray = [];
      response.map(error => {
        errorArray.push(error);
      });
      this.setState({
        errorArray,
        isFileSelected: false,
        isUploading: false,
        isFileUploadAllowed: true,
        isFileUploadComplete: false
      });
    }
    else if(!response){
        let successMessage ="Demand file has been loaded successfully!";
        this.setState({
            successMessage,
            isFileSelected: false,
            isUploading: false,
            isFileUploadAllowed: true,
            isFileUploadComplete: false
          });
    }
    
    
  };
  renderSuccessMessage = () => {
    const { successMessage } = this.state;

    if (successMessage) {
      return (
        <Message success onDismiss={() => this.setState({ successMessage: null })}>
          {successMessage}
        </Message>
      );
    } else {
      return null;
    }
  };
  handleReset = () => {
    this.setState({
      fileType: null,
      isFileSelected: false,
      file: null,
      fileName: null,
      isUploading: false,
      isFileUploadAllowed: true,
      isFileUploadComplete: false,
      isConfirmationOpen: false
    });
  };
  handleInvalidFileSelection = () => {
    this.setState({
      isUploading: false,
      loaderText: null,
      errorArray: ["Unable to process the selected file. Please select a valid file for upload"]
    });
  };
    
  renderFileUpload = () => {
    const { isFileUploadAllowed, successMessage, errorArray, fileType, file} = this.state;
    if (!isFileUploadAllowed) return null;

    return (
      <React.Fragment>
        <Form error onSubmit={this.handleFormSubmitClick}>
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                <Form.Select
                  label="File Upload Type"
                  placeholder="Select File Upload Type"
                  name="fileType"
                  value={fileType}
                  options={[
                    { key: "BSE", value: "BSE", text: "BSE File" },
                    { key: "MSE", value: "MSE", text: "MSE File" }
                  ]}
                  onChange={(e, fieldProps) => this.setState({ fileType: fieldProps.value, errorArray: [], successMessage : null })}
                  disabled={fileType && file}
                />
              </Grid.Column>
              {fileType ? (
                <Grid.Column width="10" verticalAlign="bottom">
                  <FileInput
                    onSelect={this.handleFileSelect}
                    buttonProps={{ content: "Browse file", icon: "browser", labelPosition: "left" }}
                    popupContent="Click here to browse and select file"
                  />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </Form>
        <br />
        
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column width="10">
              <Button.Group size="mini">
                <Button
                  icon="upload"
                  content="Upload"
                  labelPosition="left"
                  animated
                  color="orange"
                  disabled={!fileType || !file || (errorArray && errorArray.length > 0)}
                  onClick={this.handleFormSubmitClick}
                />
                <Button
                  icon="refresh"
                  content="Reset"
                  labelPosition="right"
                  onClick={this.handleReset}
                />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderErrorSymmary()}
         {this.renderSuccessMessage()}
      </React.Fragment>
    );
  };
  renderErrorSymmary = () => {
    const { errorArray } = this.state;

    if (!errorArray || errorArray.length == 0) return null;

    return (
      <Message error>
        Following validation checks have failed:
        <ul>
          {errorArray.map(message => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </Message>
    );
  };
  
  renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to="/">Home</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Demand File Upload</Breadcrumb.Section>
      <br />
      <br />
    </Breadcrumb>
  );
  renderLoader = () => {
    const { isUploading, loaderText } = this.state;
    return (
      <Dimmer active={isUploading}>
        <Loader active={isUploading} content={loaderText || "Uploading file..."} />
      </Dimmer>
    );
  };
  renderConfirmBox = () => {
    const { isConfirmationOpen, isUploading } = this.state;

    let confirmMessage = "Do you want upload the file?";
      confirmMessage = (
        <div>
          Do you want to continue?
         </div>
      );
    return (
        <ConfirmModal
          handleCancel={() => this.setState({ isConfirmationOpen: false })}
          handleConfirm={this.handleFormSubmit}
          isOpen={isConfirmationOpen}
          isSubmitting={isUploading}
          header="Confirm"
          text={confirmMessage}
          loadingText="Please wait.."
        />
      );
    };
    render() {
        const { fileType, isFileSelected, isFileUploadAllowed } = this.state;
        console.log(this.state);
        return (
          <Segment color="orange">
            {this.renderConfirmBox()}
            {this.renderLoader()}
            <Grid>
              <GridColumn textAlign="right">
             <Button 
              color="orange"
              as={Link}
              to="/utils/bse-filegen-tracker"
              content="Back"
              labelPosition="left"
              icon="caret left"
              size="small"
            />
            </GridColumn>
            </Grid>
            <Divider horizontal>
              <h4>
                <Icon name="upload" /> Demand File Upload
              </h4>
            </Divider>
	<Grid>
            <GridColumn width={16} textAlign="right">
            <Popup
              trigger={
                <Link to="/filegen-tracker/demandfileupload-log">
                  <Icon name="history" /> Previous Uploads
                </Link>
              }
              position="bottom center"
              content="View all previous file uploads"
              inverted
              size="small"
            />
            </GridColumn>
            </Grid>
        
            {this.renderFileUpload()}
    
            {isFileSelected ? (
              <Message warning>
                <Icon loading name="cog" />
                Click on upload <Icon name="upload" /> button to upload the selected file
              </Message>
            ) : null}
    
            {isFileUploadAllowed && !fileType ? (
              <Message warning>
                <Icon name="cog" />
                Please Select File upload type
              </Message>
            ) : null}
            
          </Segment>
        );
      }
}
  