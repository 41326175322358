import * as React from "react";
import { Component } from "react";
import { Input, Popup } from "semantic-ui-react";
import * as uuid from "uuid";

export class FileInput extends Component {
  state = {
    fileName: null
  };
  constructor(props) {
    super(props);

    this.id = uuid.v1();
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  render() {
    return (
      <React.Fragment>
        <Popup
          trigger={
            <Input
              action={{
                ...this.props.buttonProps,
                labelPosition: "left",
                as: "label",
                htmlFor: this.id
              }}
              as="label"
              htmlFor={this.id}
              actionPosition="left"
              placeholder={this.props.placeholder || "Select File"}
              value={this.state.fileName || ""}
              disabled={this.props.onSelect === null}
              fluid
              readOnly
            />
          }
          content={this.props.popupContent}
          size="tiny"
        />

        <input
          {...this.props.fileInputProps}
          hidden
          id={this.id}
          type="file"
          onChange={this.onChangeFile}
          disabled={this.props.onSelect === null}
        />
      </React.Fragment>
    );
  }

  onChangeFile() {
    const fileButton = document.getElementById(this.id);
    const file = fileButton ? fileButton.files[0] : null;

    // If parent component has declared an OnSelect handler
    if (this.props.onSelect) {
      // If user has selected a file
      if (file) {
        this.setState({ fileName: file.name });
        this.props.onSelect(file, this.props.index);
      } else {
        this.setState({ fileName: null });
        this.props.onSelect(null, this.props.index);
      }
    }
  }
}

export default FileInput;
