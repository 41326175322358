import React from "react";

import { Icon, Message } from "semantic-ui-react";

const footerLinkStyle = {
  fontWeight: "bold",
  color: "white",
  textDecoration: "underline"
};

const FooterBar = () => (
  <footer>
    <Message color="black" size="mini">
      <div style={{ display: "inline" }}>
        <div style={{ display: "inline" }}>
          <Icon name="copyright outline" />
          Copyright 2019 NSE. All rights reserved.
        </div>
        <div style={{ display: "inline", float: "right" }}>
          <a href="javascript:;" style={footerLinkStyle}>
            Disclaimer
          </a>{" "}
          &nbsp; | &nbsp;
          <a href="javascript:;" style={footerLinkStyle}>
            Help
          </a>{" "}
          &nbsp; |&nbsp;
          <a href="javascript:;" style={footerLinkStyle}>
            Contact Us
          </a>
        </div>
      </div>
    </Message>
  </footer>
);

export default FooterBar;
