import _ from "lodash";

export const ACL = [
  { path: "/order/entry", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] },
  { path: "/order/entry/:symbol/:series", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] },
  { path: "/order/modify", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] },
  { path: "/order/modify/:orderId", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] },
  { path: "/order/cancel/:orderId", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] },
  { path: "/order/cancel", roles: ["ROLE_0", "ROLE_4", "ROLE_5"] }
];

export const isAuthorized = (path, auth) => {
  // if auth info unavailable, return to dashboard
  if (!auth || !auth.userInfo || !auth.userInfo.roles[0]) return false;

  // Get role
  const role = auth.userInfo.roles[0];

  // Get access rule from ACL
  const accessRule = _.find(ACL, { path: path });

  // if rule unavailable, return to dashboard
  if (!accessRule) return false;

  // If rule contains the role, return authorized or unauthorize
  if (_.includes(accessRule.roles, role)) return true;
  else return false;
};
