import React from "react";

import { Icon, Label, Table, Card, Popup, Placeholder, Segment } from "semantic-ui-react";

import DataFetchTimeStamp from "../utils/DataFetchTimeStamp";

import rmsApi from "../../apis/rmsApi";

export default class InterfaceConnectivityStatus extends React.Component {
  state = {
    connectionStatus: null,
    fetchTimeStamp: null
  };

  interval = null;

  componentDidMount() {
    this.fetchConnectivityStatus();
    // this.startInterval();
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  startInterval = () => {
    this.interval = window.setInterval(this.fetchConnectivityStatus, 10000);
  };

  stopInterval = () => {
    window.clearInterval(this.interval);
    this.interval = null;
  };

  fetchConnectivityStatus = () => {
    this.setState({ connectionStatus: null });
    rmsApi
      .get("/interface/status")
      .then(response => {
        if (response.data) {
          let fetchTimeStamp = new Date(response.headers.date);
          this.setState({ connectionStatus: response.data, fetchTimeStamp });
        }
      })
      .catch(error => {
        console.log(error);
        this.stopInterval();
      });
  };

  renderIcon = connectionStatus => {
    if (connectionStatus === 0 || connectionStatus === 1)
      return (
        <Popup
          trigger={
            <span>
              <Icon.Group>
                <Icon name="wifi" color="red" />
                <Icon corner="top right" name="close" color="red" />
              </Icon.Group>
              <b style={{ color: "red", fontSize: 10 }}>&nbsp;Not Connected</b>
            </span>
          }
          content="OFS is waiting for the interface to connect."
          position="right center"
          size="small"
          inverted
        />
      );
    else if (connectionStatus === 2) {
      return (
        <Popup
          trigger={
            <span>
              <Icon.Group>
                <Icon name="wifi" color="orange" />
                <Icon corner="top right" name="arrows alternate horizontal" color="orange" />
              </Icon.Group>
              <b style={{ color: "orange", fontSize: 10 }}>&nbsp;Not Connected</b>
            </span>
          }
          content="Connection has been established with the interface."
          position="right center"
          size="small"
          inverted
        />
      );
    } else if (connectionStatus === 3) {
      return (
        <Popup
          trigger={
            <span>
              <Icon.Group>
                <Icon name="wifi" color="green" />
                <Icon corner="top right" name="checkmark" color="green" />
              </Icon.Group>
              <b style={{ color: "green", fontSize: 10 }}>&nbsp;Connected</b>
            </span>
          }
          content="Connection has been established with the interface, and online data feed is being processed."
          position="right center"
          size="small"
          inverted
        />
      );
    }
  };

  render() {
    const { connectionStatus, fetchTimeStamp } = this.state;

    return (
      <Card fluid color="orange">
        <Card.Content>
          <Label as="a" color="orange" ribbon size="large">
            <Icon name="rss" /> Interface Connectivity
          </Label>
          <div style={{ float: "right" }}>
            <Popup
              trigger={
                <a href="javascript:;" onClick={this.fetchConnectivityStatus} size="mini">
                  <Icon name="refresh" />
                </a>
              }
              content="Refresh data"
              on="hover"
            />
          </div>
          <br />
          <span style={{ float: "right" }}>
            <DataFetchTimeStamp fetchTimeStamp={fetchTimeStamp} />
          </span>
        </Card.Content>
        {!connectionStatus ? (
          <Segment>
            <Placeholder>
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
              <Placeholder.Line length="full" />
            </Placeholder>
          </Segment>
        ) : (
          <Table compact striped attached>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Rms Server</Table.Cell>
                <Table.Cell>
                  {this.renderIcon(connectionStatus.rmsServerConnectionStatus)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Rms Receiver</Table.Cell>
                <Table.Cell>
                  {this.renderIcon(connectionStatus.rmsReceiverConnectionStatus)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Rms Approve Receiver</Table.Cell>
                <Table.Cell>
                  {this.renderIcon(connectionStatus.rmsApproveReceiverConnectionStatus)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Combiner</Table.Cell>
                <Table.Cell>
                  {this.renderIcon(connectionStatus.survServerConnectionStatus)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Card>
    );
  }
}
