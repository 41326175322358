import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { authenticateToken } from "../../actions";
import Cookies from "universal-cookie";
import PageLoader from "./PageLoader";

class LoginRequiredRoute extends React.Component {
  // componentDidMount() {
  //   console.log("LoginRequiredRoute componentDidMount");
  //   if (process.env.NODE_ENV !== "development") {
  //     this.executeAuthentication();
  //   }
  // }
  componentDidUpdate() {
    this.executeAuthentication();
  }

  executeAuthentication = () => {
    let token = null;

    // LOGIC TO UPDATE REFRESH TOKEN IN COOKIE AND REDUX STORE,
    // This is commented. Remove this when login module is stable
    /*
    if (store.getState() && store.getState().auth && store.getState().auth.accessToken) {
      console.log(store.getState());
      token = store.getState().auth.accessToken;
      console.log(token);
    }
    */

    // Authenticate token

    const cookies = new Cookies();
    token = cookies.get("IDENTITY");
    console.log(token);

    if (token) {
      this.props.authenticateToken(token);
    } else {
      this.props.authenticateToken("");
    }
  };

  render() {
    console.log("LoginRequiredRoute render");
    console.log(this.props);
    if (this.props.isSignedIn == null) {
      this.executeAuthentication();
      return <PageLoader loaderText="Please wait..." />;
    }

    return (
      <Route
        {...this.props.rest}
        render={props =>
          this.props.isSignedIn ? (
            <this.props.component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: this.props.location }
              }}
            />
          )
        }
      />
    );
  }
}

/*
const LoginRequiredRoute = ({ component: Component, ...rest }) => {
  // Authenticate token
  const cookies = new Cookies();
  const token = cookies.get("IDENTITY");

  if (token) {
    rest.authenticateToken(token);
    console.log(rest.isSignedIn);
  }

  return (
    <Route
      {...rest}
      render={props =>
        rest.isSignedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
*/
export default connect(
  state => ({
    isSignedIn: state.auth.isSignedIn
  }),
  { authenticateToken }
)(LoginRequiredRoute);
