import React from "react";
import { Segment, Icon, Statistic } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class OrderStats extends React.Component {
  render() {
    return (
      <Segment color={this.props.color} textAlign="center" secondary raised>
        {this.props.icon ? (
          <React.Fragment>
            <Icon name={this.props.icon} color={this.props.color} size="big" />
            &nbsp;&nbsp;
          </React.Fragment>
        ) : null}
        <Statistic size="small" as={Link} to={this.props.to}>
          <Statistic.Value>{this.props.count}</Statistic.Value>
          <Statistic.Label>{this.props.type}</Statistic.Label>
        </Statistic>
      </Segment>
    );
  }
}
