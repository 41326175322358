import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import authReducer from "./authReducer";
import masterReducer from "./masterReducer";

export default combineReducers({
  auth: authReducer,
  masters: masterReducer,
  form: reduxFormReducer
});
