export const SIGN_IN = "SIGN_IN";
export const AUTHENTICATE_USER_TOKEN = "AUTHENTICATE_USER_TOKEN";
export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN";
export const UPDATE_TOKEN_IN_STATE = "UPDATE_TOKEN_IN_STATE";
export const CLEAR_TOKEN_IN_STATE = "CLEAR_TOKEN_IN_STATE";
export const SIGN_OUT = "SIGN_OUT";

export const MAS_BRANCH_CREATE = "MAS_BRANCH_CREATE";
export const MAS_BRANCH_MODIFY = "MAS_BRANCH_MODIFY";

export const MAS_RESET_VALIDATION = "MAS_RESET_VALIDATION";
