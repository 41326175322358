import { MAS_BRANCH_CREATE, MAS_BRANCH_MODIFY, MAS_RESET_VALIDATION } from "../actions/types";

const INITIAL_STATE = {
  branchCreateResponse: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAS_BRANCH_CREATE:
      return {
        ...state,
        branchCreateResponse: action.payload
      };

    case MAS_RESET_VALIDATION:
      return {
        ...state,
        branchCreateResponse: action.payload
      };
    default:
      return state;
  }
};
