import React from "react";
import { Modal, Icon, Segment } from "semantic-ui-react";

const PasswordPolicy = props => {
  console.log(props);

  return (
    <Modal size="tiny" open={props.isVisible} onClose={props.onClose} closeIcon>
      <Modal.Header>
        <Icon name="lock" />
        Password Policy
      </Modal.Header>
      <Modal.Content>
        <ul>
          <li>Minimum password length of 12 characters.</li>
          <li>
            Password must be a combination of following characters:
            <ol>
              <li>Upper case alphabets</li>
              <li>Lower case alphabets</li>
              <li>Numbers</li>
              <li>Special characters</li>
            </ol>
          </li>
          <li>Password will expire after 365 Days of password change.</li>
          <li>Maximum 5 Attempts in case of wrong password entered.</li>
          <li>Last 5 passwords are not allowed.</li>
        </ul>
        <br />
        <br />
      </Modal.Content>
    </Modal>
  );
};

export default PasswordPolicy;
