import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Icon, Breadcrumb, Grid, Segment, Button, Popup, Loader, Dimmer } from "semantic-ui-react";
import FilterInput from "../utils/FilterInput";
import schedulerApi from "../../apis/schedulerApi";
import { fetchUserInfo } from "../../utils/commonUtils";
import { API_BASE_URL } from "../../applicationConstants";
export default class DemandFileUploadLog extends React.Component {
  state = {
    userInfo: null,
    data: [],
    pages: null,
    loading: true,
    isDownloading: false
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    fetchUserInfo()
      .then(response => {
        const userInfo = response.data;
        if (userInfo) {
          this.setState({
            userInfo
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  requestData = async (pageSize, page, sorted, filtered) => {
    let postObject = {
      page: page,
      pageSize: pageSize,
      sorted: sorted,
      filtered: filtered
    };
    return await schedulerApi.post("/demanddata/fileUploads", postObject);
  };

  fetchData = (state, instance) => {
    this.setState({ loading: true });

    this.requestData(state.pageSize, state.page, state.sorted, state.filtered).then(res => {
      this.setState({
        data: res.data.content,
        pages: res.data.totalPages,
        loading: false
      });
    });
  };

  downloadResponseFile = fileName => {
    let getUrl = "";
    this.setState({ isDownloading: true });
      getUrl = `${API_BASE_URL}/scheduler/demanddata/file/response?fileName=${fileName}`;
      window.open(getUrl, "_blank");
    this.setState({ isDownloading: false });
  };

  renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to="/">Home</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section>
        <Link to="/filegen-tracker/demandfileupload">Demand File Upload</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Previous Uploads</Breadcrumb.Section>
    </Breadcrumb>
  );

  renderLoader = () => {
    const { isDownloading } = this.state;
    return (
      <Dimmer active={isDownloading}>
        <Loader active={isDownloading} content="Downloading demand response file, Please wait..." />
      </Dimmer>
    );
  };

  render() {
    const { data, pages, loading } = this.state;
    return (
      <Segment color="orange">
        {this.renderLoader()}

        <Grid>
          <Grid.Column width={8}>{this.renderBreadcrumb()}</Grid.Column>
          <Grid.Column width={8} textAlign="right"></Grid.Column>
        </Grid>
        <br />
        <ReactTable
          ref={r => {
            this.reactTableRef = r;
          }}
          columns={[
           
            {
              Header: "File Type",
              accessor: "fileType",
              Cell: rowData => {
                const { fileType } = rowData.original;
                if (fileType === "BSEFile") return "BSE File";
                if (fileType === "MSEFile") return "MSE File";

                return "";
              },
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    value={filter ? filter.value : ""}
                    onChange={event => onChange(event.target.value)}
                  >
                    <option value="">Select Type</option>
                    <option value="BSEFile">BSE File</option>
                    <option value="MSEFile">MSE File</option>
                  </select>
                );
              },
              width: 130
            },
            {
              Header: "Status",
              accessor: "status",
              width: 130,
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    value={filter ? filter.value : ""}
                    onChange={event => onChange(event.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="Success">Success</option>
                    <option value="Error">Error</option>
                  </select>
                );
              }
            },
            {
              Header: "Uploaded By",
              accessor: "updatedBy",
              width: 100,
              Filter: filterProps => <FilterInput filterProps={filterProps} />
            },
            {
              Header: "Uploaded At",
              accessor: "updatedDate",
              Cell: rowData => {
                return rowData.original.statusDate.substring(0, 19).replace("T", " ");
              },
              width: 150,
              Filter: filterProps => <FilterInput filterProps={filterProps} />
            }, {
                Header: "File Name",
                accessor: "fileName",
                width: 250,
                Cell: rowData => {
                  const fileName = rowData.original.fileName;
                  if (!fileName) {
                    return "";
                  }
                  return (
                    <span>
                         {rowData.original.status === "Error" ? null :
                      <Popup
                        trigger={
                          <a
                            href="javascript:;"
                            onClick={() =>
                              this.downloadResponseFile(rowData.original.fileName)
                            }
                          >
                            <Icon name="download" />
                          </a>
                        }
                        content="Download file"
                        size="tiny"
                        position="left center"
                        inverted
                      />}
                      {fileName}
                    
                    </span>
                  );
                },
                filterable: false,
                sortable: false
              },
              {
                Header: "Error Message",
                accessor: "errorMessages",
                Cell: rowData => {
                    const errorMessage = rowData.original.errorMessages;
                    if (!errorMessage) {
                      return "";
                    }
                    return errorMessage;
                 } 
                }
          ]}
          manual
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          noDataText="No data available"
        />
        <br />
      </Segment>
    );
  }
}
