import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Segment,
  Icon,
  Grid,
  Placeholder,
  Popup,
  Dimmer,
  Loader,
  Button,
  Divider,
  Message,
  Form,
  GridRow
} from "semantic-ui-react";

import allocationApi from "../../apis/allocationApi";
import DataFetchTimeStamp from "../utils/DataFetchTimeStamp";
import OrderStats from "../dashboard/OrderStats";

import {
  SECURITY_STATUS_APPROVED,
  SECURITY_STATUS_DRAFTED,
  SECURITY_STATUS_PROVISIONAL,
  SECURITY_STATUS_REWORK,
  SECURITY_STATUS_VERIFICATION,
  PROCESS_DSE_TDAY,
  PROCESS_DSE_T1DAY,
  PROCESS_NON_DSE_TDAY,
  PROCESS_NON_DSE_T1DAY,
  ALLOCATION_PROCESS_STATUS_STARTED,
  getProcessTypeText
} from "./allocationHelpers";

export default class AllocationDashboard extends React.Component {
  state = {
    setupStats: null,
    setupStatsFetchTimeStamp: null,
    allocationProcessStats: null,
    allocationProcessStatsFetchTimeStamp: null,
    isLoading: false
  };

  componentDidMount() {
    this.fetchSetupStats();
    this.fetchAllocationProcessStats();
  }

  fetchSetupStats = () => {
    this.setState({ isLoading: true });
    allocationApi
      .get("/security-stats")
      .then(resp => {
        const setupStatsFetchTimeStamp = new Date(resp.headers.date);
        this.setState({ isLoading: false, setupStats: resp.data, setupStatsFetchTimeStamp });
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  fetchAllocationProcessStats = () => {
    this.setState({ isLoading: true });
    allocationApi.get("/proc/stats").then(resp => {
      const allocationProcessStatsFetchTimeStamp = new Date(resp.headers.date);
      this.setState({
        isLoading: false,
        allocationProcessStats: resp.data,
        allocationProcessStatsFetchTimeStamp
      });
    });
  };

  getAllocationStatsLabel = (processType, status) => {
    const processTypeText = getProcessTypeText(processType);

    return `${processTypeText} (${status})`;
  };

  render() {
    const {
      setupStats,
      setupStatsFetchTimeStamp,
      allocationProcessStats,
      allocationProcessStatsFetchTimeStamp
    } = this.state;

    return (
      <Grid columns={4} stackable padded>
        <Grid.Row>
          <Grid.Column width="16">
            <Divider horizontal>
              <h4>
                <Icon name="chart area" /> Master Setup Summary{" "}
                <Popup
                  trigger={
                    <a href="javascript:;" onClick={this.fetchSetupStats} size="mini">
                      <Icon name="refresh" />
                    </a>
                  }
                  content="Refresh stats"
                  on="hover"
                />
              </h4>
            </Divider>

            <DataFetchTimeStamp fetchTimeStamp={setupStatsFetchTimeStamp} />
          </Grid.Column>
        </Grid.Row>
        {setupStats ? (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column width="three">
                <OrderStats
                  type={SECURITY_STATUS_PROVISIONAL}
                  count={setupStats[SECURITY_STATUS_PROVISIONAL]}
                  icon="file new"
                  color="grey"
                  to="/allocation/master-setup-list/provisional"
                />
              </Grid.Column>
              <Grid.Column width="three">
                <OrderStats
                  type={SECURITY_STATUS_DRAFTED}
                  count={setupStats[SECURITY_STATUS_DRAFTED]}
                  icon="save"
                  color="grey"
                  to="/allocation/master-setup-list/drafted"
                />
              </Grid.Column>
              <Grid.Column width="4">
                <OrderStats
                  type={"For Verification"}
                  count={setupStats[SECURITY_STATUS_VERIFICATION]}
                  icon="check"
                  color="blue"
                  to="/allocation/master-setup-list/verification"
                />
              </Grid.Column>
              <Grid.Column width="three">
                <OrderStats
                  type={SECURITY_STATUS_REWORK}
                  count={setupStats[SECURITY_STATUS_REWORK]}
                  icon="history"
                  color="red"
                  to="/allocation/master-setup-list/rework"
                />
              </Grid.Column>
              <Grid.Column width="three">
                <OrderStats
                  type={SECURITY_STATUS_APPROVED}
                  count={setupStats[SECURITY_STATUS_APPROVED]}
                  icon="check circle"
                  color="orange"
                  to="/allocation/master-setup-list/approved"
                />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ) : (
          <Grid.Row>
            <Grid.Column width="three">
              <Segment color="grey" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="three">
              <Segment color="grey" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment color="blue" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="3">
              <Segment color="red" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="3">
              <Segment color="orange" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width="16">
            <Divider horizontal>
              <h4>
                <Icon name="chart area" /> Allocation Process Summary{" "}
                <Popup
                  trigger={
                    <a href="javascript:;" onClick={this.fetchAllocationProcessStats} size="mini">
                      <Icon name="refresh" />
                    </a>
                  }
                  content="Refresh stats"
                  on="hover"
                />
              </h4>
            </Divider>

            <DataFetchTimeStamp fetchTimeStamp={allocationProcessStatsFetchTimeStamp} />
          </Grid.Column>
        </Grid.Row>
        {allocationProcessStats ? (
          <React.Fragment>
            <Grid.Row>
              <Grid.Column width="4">
                <OrderStats
                  type={this.getAllocationStatsLabel(
                    PROCESS_DSE_TDAY,
                    ALLOCATION_PROCESS_STATUS_STARTED
                  )}
                  count={
                    allocationProcessStats[
                      `${PROCESS_DSE_TDAY}_${ALLOCATION_PROCESS_STATUS_STARTED}`
                    ]
                  }
                  color="violet"
                  to="/allocation/process/list"
                />
              </Grid.Column>
              <Grid.Column width="4">
                <OrderStats
                  type={this.getAllocationStatsLabel(
                    PROCESS_DSE_T1DAY,
                    ALLOCATION_PROCESS_STATUS_STARTED
                  )}
                  count={
                    allocationProcessStats[
                      `${PROCESS_DSE_T1DAY}_${ALLOCATION_PROCESS_STATUS_STARTED}`
                    ]
                  }
                  color="violet"
                  to="/allocation/process/list"
                />
              </Grid.Column>
              <Grid.Column width="4">
                <OrderStats
                  type={this.getAllocationStatsLabel(
                    PROCESS_NON_DSE_TDAY,
                    ALLOCATION_PROCESS_STATUS_STARTED
                  )}
                  count={
                    allocationProcessStats[
                      `${PROCESS_NON_DSE_TDAY}_${ALLOCATION_PROCESS_STATUS_STARTED}`
                    ]
                  }
                  color="grey"
                  to="/allocation/process/list"
                />
              </Grid.Column>

              <Grid.Column width="4">
                <OrderStats
                  type={this.getAllocationStatsLabel(
                    PROCESS_NON_DSE_T1DAY,
                    ALLOCATION_PROCESS_STATUS_STARTED
                  )}
                  count={
                    allocationProcessStats[
                      `${PROCESS_NON_DSE_T1DAY}_${ALLOCATION_PROCESS_STATUS_STARTED}`
                    ]
                  }
                  color="grey"
                  to="/allocation/process/list"
                />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ) : (
          <Grid.Row>
            <Grid.Column width="4">
              <Segment color="violet" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment color="violet" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment color="grey" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment color="grey" raised>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}
