import React from "react";
 
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
 
import { signIn, authenticateToken } from "../actions";
 
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Icon,
  Loader,
  Dimmer,
  Image,
  Divider,
  Popup
} from "semantic-ui-react";
 
import PageLoader from "./utils/PageLoader";
import CryptoJS from "crypto-js";
import authApi from "../apis/auth";
 
import { STATIC_FILE_PATH } from "../applicationConstants";
// import { generateBrowserSignature } from "../utils/commonUtils";
 
import isEqual from "lodash/isEqual";
 
const LoginFormHeader = ({ text }) => (
  <Grid stackable celled="internally">
    <Grid.Row>
      <Grid.Column width={7} textAlign="right">
        <Image
          src={`${process.env.PUBLIC_URL}/nse_ofs_logo_new.png`}
          size="medium"
          verticalAlign="middle"
        />
      </Grid.Column>
      <Grid.Column width={9} verticalAlign="middle" textAlign="left">
        <Header as="h3" color="orange">
          {text}
        </Header>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
 
class LoginPage extends React.Component {
  state = {
    loginForm: {
      username: "",
      password: "",
      memberId: ""
    },
    otpForm: {
      otp: ""
    },
    loginResponseToken: null,
    apiMessages: [],
    errors: {},
    touched: {},
    isLoginValidated: null,
    otpAttemptCount: 5,
    resendCount: null,
    isLoaderVisible: false,
    countDown: {
      minutes: 2,
      seconds: 0
    }
  };
 
  INITIAL_STATE = {
    loginForm: {
      username: "",
      password: "",
      memberId: ""
    },
    otpForm: {
      otp: ""
    },
    loginResponseToken: null,
    apiMessages: [],
    loginApiMessages: [],
    errors: {},
    touched: {},
    isLoginValidated: null,
    otpAttemptCount: 5,
    resendCount: null,
    isLoaderVisible: false,
    countDown: {
      minutes: 2,
      seconds: 0
    },
    randomstring : null,
    validateForm : true
  };
 
  componentDidMount() {
    const cookies = new Cookies();
    const token = cookies.get("IDENTITY");
    this.setState({randomstring: Math.random().toString(36).slice(8)});
    if (token) {
      this.props.authenticateToken(token);
    } else {
      this.props.authenticateToken("");
    }
  }
 
  componentWillUnmount() {
    try {
      window.clearInterval(this.interval);
    } catch (e) {}
  }
 
  interval = null;
  startCountDown = () => {
    this.interval = window.setInterval(this.countDown, 1000);
  };

  refreshString = (e) =>{
    e.preventDefault();
    this.setState({randomstring: Math.random().toString(36).slice(8)});
  };
  countDown = () => {
    let { minutes, seconds } = this.state.countDown;
 
    if (seconds === 0) {
      seconds = 59;
      minutes = minutes - 1;
    } else {
      seconds = seconds - 1;
    }
    if (seconds <= 0 && minutes <= 0) {
      window.clearInterval(this.interval);
    }
    this.setState({
      countDown: {
        minutes,
        seconds
      }
    });
  };
 
  validateField = (label, name, value) => {
    let errors = new Object();
    if (!value) {
      errors[name] = `Please enter ${label}.`;
    }
    return errors;
  };
 
  validateForm = formData => {
    let errors = {};
    if (!formData.memberId) {
      errors.memberId = `Please enter Member Id`;
    }
 
    if (!formData.username) {
      errors.username = `Please enter User Id`;
    } else if (isNaN(formData.username)) {
      errors.username = `Please enter a valid User Id`;
    }
 
    if (!formData.password) {
      errors.password = `Please enter Password`;
    }

    // if(this.refreshString || ){
    //   return {}
    // }
    if (!formData.captcha ) {
      errors.captcha = `Please enter Captcha`;
    }
    return errors;
  };
 
  generateBrowserSignature = () => {
    try {
      let browserSignature = (function(window, screen, navigator) {
        // https://github.com/darkskyapp/string-hash
        function checksum(str) {
          let hash = 5381,
            i = str.length;
 
          while (i--) hash = (hash * 33) ^ str.charCodeAt(i);
 
          return hash >>> 0;
        }
 
        // http://stackoverflow.com/a/4167870/1250044
        function map(arr, fn) {
          let i = 0,
            len = arr.length,
            ret = [];
          while (i < len) {
            ret[i] = fn(arr[i++]);
          }
          return ret;
        }
 
        return checksum(
          [
            navigator.userAgent,
            [screen.height, screen.width, screen.colorDepth].join("x"),
            new Date().getTimezoneOffset(),
            !!window.sessionStorage,
            !!window.localStorage,
            map(navigator.plugins, function(plugin) {
              return [
                plugin.name,
                plugin.description,
                map(plugin, function(mime) {
                  return [mime.type, mime.suffixes].join("~");
                }).join(",")
              ].join("::");
            }).join(";")
          ].join("###")
        );
      })(this, window.screen, window.navigator);
      console.log(browserSignature);
      return browserSignature;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  handleInput = (e, fieldProps) => {
   
    let currentState = { ...this.state };
    let value = fieldProps.value;
    let name = fieldProps.name;
    let label = fieldProps.placeholder;
 
    if (name === "memberId") {
      currentState.loginForm[name] = value ? value.toUpperCase() : "";
    } else {
      currentState.loginForm[name] = value;
    }
    currentState.touched[name] = true;
    let validationErrors = this.validateField(label, name, value);
    currentState.errors = { ...validationErrors };
    this.setState(currentState);
    
  };

  handleCaptchaKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission when Enter key is pressed
    }
  };
 
  handleOTPInput = (e, fieldProps) => {
    let currentState = { ...this.state };
    let value = fieldProps.value;
    let name = fieldProps.name;
 
    currentState.otpForm.otp = value;
 
    let validationMessages = [];
    if (!value) validationMessages.push("Please enter OTP received on your mobile and email-id");
    if (isNaN(value)) validationMessages.push("Please enter a valid OTP");
 
    currentState.apiMessages = validationMessages;
 
    this.setState(currentState);
  };

  handleSubmit = (e) => {
    this.handleSubmitData();
  };
 

  handleSubmitEnterPress = (e) => {
    
    if(e.key === 'Enter')
    {
     e.preventDefault();
     this.handleSubmitData();
    }
  };

  handleSubmitData = () => {
    console.log(this.state.loginForm);
    let errors = this.validateForm(this.state.loginForm);
    const obj = new Object();
    if (!isEqual(errors, obj)) {
      let touched = { memberId: true, username: true, password: true,captcha: true };
      this.setState({ errors, touched });
      return;
    }
    this.setState({ isLoaderVisible: true });
    let currentState = JSON.parse(JSON.stringify({ ...this.state }));
    
      let data = this.state.loginForm.password;
      const key  = CryptoJS.enc.Latin1.parse('1234567812345678');
      const iv   = CryptoJS.enc.Latin1.parse('1234567812345678');  
      let encrypted = CryptoJS.AES.encrypt(data,key, {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding}).toString();
          
    currentState.loginForm.password = encrypted;
    // if(this.state.loginForm.captcha === this.state.randomstring)
    // {
    authApi
     // .post("/login", currentState.loginForm, )
     .post(`/login?captcha=${this.state.loginForm.captcha}&&captchaf=${this.state.randomstring}`,currentState.loginForm)
      .then(response => {
        console.log(response.data);
        if (response.data.messages && response.data.messages.length > 0) {
          if (response.data.messages[0] === "Password has expired! Kindly change password.") {
            window.location.href = `${STATIC_FILE_PATH}#/password-expired/${response.data.accessToken}/1`;
          }
 
          this.setState({
            isLoginValidated: false,
            isLoaderVisible: false,
            loginApiMessages: response.data.messages
          });
        } else {
          this.setState(
            {
              isLoginValidated: true,
              loginResponseToken: response.data.accessToken,
              isLoaderVisible: false,
              countDown: { minutes: 2, seconds: 0 },
              otpAttemptCount: 5,
              loginApiMessages: [],
              IS2FAEnabled : response.data.twoFAEnabled
            },
            this.startCountDown
          );
        }
        if(this.state.IS2FAEnabled == false)
        {
          
          this.setState({ otpForm: { otp: "" } }, () => window.setTimeout(() => this.onOTPSubmitClick(), 1));
        }
      })
      .catch((error, body) => {
        console.log(error);
        console.log(body);
        this.setState({ isLoginValidated: false, isLoaderVisible: false });
      });
    // }
    // else
    // {
    //   this.setState({ isLoginValidated: false, isLoaderVisible: false ,loadingCaptcha:"Invalid Captcha"});
    // }
  }
 
  handleRegenerateOtp = () => {
    const regenerateData = {
      userId: this.state.loginForm.username,
      token: this.state.loginResponseToken,
      purpose: "Login"
    };
 
    let resendCount = this.state.resendCount || -1;
    resendCount++;
    authApi
      .post("/regenerateOTP", regenerateData)
      .then(response => {
        console.log(response.data);
        this.setState(
          {
            isLoginValidated: true,
            loginResponseToken: response.data.accessToken,
            resendCount,
            isLoaderVisible: false,
            countDown: { minutes: 2, seconds: 0 }
          },
          this.startCountDown
        );
      })
      .catch(error => {
        console.log(error);
      });
  };
 
  onOTPSubmitClick = () => {
    const otp = this.state.otpForm.otp;
    const userId = this.state.loginForm.username;
    const token = this.state.loginResponseToken;
    const browserSignature = this.generateBrowserSignature();
    localStorage.setItem('userId',userId);
 
 
    const cookies = new Cookies();
    cookies.set("BS", browserSignature, { path: "/" });
 
    let formData = { otp, userId, token: token, browserSignature, purpose: "Login" };
    console.log(formData);
    let { otpAttemptCount } = this.state;
    if (otpAttemptCount <= 1) {
      console.log(this.INITIAL_STATE);
      this.setState({ ...this.INITIAL_STATE }, () => console.log(this.state));
    }
    this.setState({ otpAttemptCount: otpAttemptCount - 1 });
 
    this.setState({ isLoaderVisible: true });
    // Flags for handling the login error thrown by the Auth server
 
    authApi
      .post("/validateOTP", formData)
      .then(response => {
        console.log(response.data);
        this.setState({ apiMessages: response.data.messages, isLoaderVisible: false });
        this.props.signIn(response.data);
      })
      .catch(error => {
        this.setState({
          apiMessages: ["Error occured while verifying OTP"],
          isLoaderVisible: false
        });
        let responseData = {};
        if (error.response) responseData = error.response.data;
        this.props.signIn(responseData);
      });
    return false;
  };
 
  handleLoginWithoutOtp = () => {

    // this.setState({ otpForm: { otp: "master" } }, () => this.onOTPSubmitClick());
    // return false;
  };
 
  renderError = (error, touched) => {
    if (touched && error)
      return (
        <Message size="mini" error>
          {error}
        </Message>
      );
  };
 
  renderOTPMessageText = () => {
    const { apiMessages } = this.state;
    if (apiMessages && apiMessages.length > 0) {
      return (
        <React.Fragment>
          {apiMessages.map(message => (
            <React.Fragment key={message}>
              <Icon name="info circle" />
              {message}
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
    return "Enter OTP";
  };
 
  renderMessageText = () => {
    const { isLoginValidated, loginApiMessages } = this.state;
    if (!isLoginValidated && loginApiMessages && loginApiMessages.length > 0) {
      return loginApiMessages[0];
    }
    // } else if (!isLoginValidated) {
    //   return "Invalid username or password";
    // }
    return "Enter your credentials";
  };

  renderCaptchaError = () => {
    if(this.state.loadingCaptcha != "")
    {
      return "Invalid Captcha";
    }
  };


  renderResendOtp = () => {
    let { minutes, seconds } = this.state.countDown;
    if (minutes === 0 && seconds === 0) {
      return (
        <div style={{ textAlign: "left", paddingTop: 10 }}>
          <a href="javascript:;" onClick={this.handleRegenerateOtp}>
            <Icon name="refresh" /> Resend OTP
          </a>
 
          <div style={{ float: "right" }}>
            <a href="/">
              <Icon name="caret left" /> Login
            </a>
          </div>
        </div>
      );
    } else {
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return (
        <div style={{ textAlign: "left", paddingTop: 10 }}>
          <a href="javascript:;">{`(Time remaining: ${minutes}:${seconds})`}</a>
        </div>
      );
    }
  };
 
  renderLoginForm = () => {
    const { username, password, memberId } = this.state.loginForm;
    const { touched, errors } = this.state;
    const handleUsernameChange = (event) => {
      console.log(handleUsernameChange);
      const key = event.key;
      if (key === 'Enter') {
        event.preventDefault(); // Prevent form submission when Enter key is pressed
      }
      if(!/[a-zA-Z0-9_]/.test(key)){
        event.preventDefault();
      }};
    return (
      <React.Fragment>
        <Form size="large" onSubmit={this.handleSubmit} className="error">
          <Segment stacked>
            <LoginFormHeader text="Log-in to your account" />
            <Divider />
            <Popup
              trigger={
                <Form.Input
                  fluid
                  name="memberId"
                  value={memberId}
                  icon="users"
                  iconPosition="left"
                  placeholder="Member Id"
                  type="text"
                  autoComplete="off"
                  maxLength="5"
                  onChange={this.handleInput}
                  onKeyPress={handleUsernameChange}
                />
              }
              on="focus"
              content="Enter Member ID"
              position="bottom right"
              inverted
              size="tiny"
            />
 
            {this.renderError(errors.memberId, touched.memberId)}
            <Popup
              trigger={
                <Form.Input
                  fluid
                  name="username"
                  value={username}
                  icon="user"
                  iconPosition="left"
                  placeholder="User ID"
                  type="number"
                  autoComplete="off"
                  maxLength="5"
                  onChange={this.handleInput}
                  onKeyPress={handleUsernameChange}
                />
              }
              on="focus"
              content="Enter User ID"
              position="bottom right"
              inverted
              size="tiny"
            />
            {this.renderError(errors.username, touched.username)}
            <Popup
              trigger={
                <Form.Input
                  fluid
                  name="password"
                  value={password}
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  maxLength="25"
                  onChange={this.handleInput}
                  onKeyPress={this.handleCaptchaKeyPress} 
                />
              }
              on="focus"
              content="Enter password"
              position="bottom right"
              inverted
              size="tiny"
            />
            {this.renderError(errors.password, touched.password)}

              <Form.Group widths="equal">
            <div className="captchadiv">{this.state.randomstring}
            <style>{`
      .captchadiv {
        color:#fff;
        text-decoration: line-through;
        user-select: none;
        background-color: #f2711c;
        border-radius: 5pa;
        width: 203.48px;
        height: 36px;
        font-size:22px;
        margin-bottom: auto;
        margin-top: 5px;
        margin-left: 7px;
        display: flex;
        text-align:center;
        justify-content:center;
        align-items: center;
    }
    `}</style>
            </div>
            <Form.Input
              fluid
              label=""
              placeholder="Captcha"
              name="captcha"
              onChange={this.handleInput}
              onKeyPress={this.handleSubmitEnterPress} // Attach handleCaptchaKeyPress function here
            />
            <div style={{ textAlign: "right", marginTop: 5,fontSize : 22,marginRight : 3 }}>
            <Button onClick={this.refreshString} >
            <Icon name="refresh" />
            </Button>
            </div>
            </Form.Group>
            
            {this.renderError(errors.captcha, touched.captcha)}
            <Button color="orange" fluid size="large">
              <Icon name="sign-in" /> Login
            </Button>
 
            <div style={{ textAlign: "right", paddingTop: 10, fontSize: 12, fontWeight: "bold" }}>
              <a href={`${STATIC_FILE_PATH}#/forgot-password`}>
                <Icon name="question circle" /> Forgot Password
              </a>
            </div>
          </Segment>
        </Form>
 
        <Message warning>
          <Message.Content>
            <Icon name="info circle" />
            {this.renderMessageText()}
          </Message.Content>
        </Message>
        {this.state.loadingCaptcha ?
        <Message error>
          <Message.Content>
            {this.renderCaptchaError()}
          </Message.Content>
        </Message>
        : null}
      </React.Fragment>
    );
  };
 
  renderOTPForm = () => {
    const { otpAttemptCount, otpForm } = this.state;
    return (
      <React.Fragment>
        <Form size="large" onSubmit={this.onOTPSubmitClick} className="error">
          <Segment stacked>
            <LoginFormHeader text="OTP verification" />
            <Divider />
 
            <Popup
              trigger={
                <Form.Input
                  fluid
                  name="otp"
                  value={otpForm.otp}
                  icon="lock"
                  iconPosition="left"
                  placeholder="Enter OTP"
                  type="password"
                  autoComplete="off"
                  maxLength="7"
                  onChange={this.handleOTPInput}
                />
              }
              on="focus"
              content="Enter OTP"
              position="bottom right"
              inverted
              size="tiny"
            />
            <Button.Group fluid>
              <Button color="orange" size="large">
                <Icon name="sign-in" /> Login
              </Button>
              {/* <Button size="large" onClick={this.handleLoginWithoutOtp} type="button">
                <Icon name="sign-in" /> Login without OTP
              </Button> */}
            </Button.Group>
            {this.renderResendOtp()}
            <hr />
            <div>Remaining attemps: {otpAttemptCount}</div>
          </Segment>
        </Form>
 
        <Message warning>
          <Message.Content>{this.renderOTPMessageText()}</Message.Content>
        </Message>
      </React.Fragment>
    );
  };
 
  render() {
    if (this.props.auth && this.props.auth.isSignedIn) {
      try {
        const { from } = this.props.location || { from: { pathname: "/" } };
        return <Redirect to={from} />;
      } catch (e) {
        return <Redirect to="/" />;
      }
    }
 
    if (this.props.auth.isSignedIn == null || this.props.auth.isSignedIn == undefined) {
      return <PageLoader loaderText="Please wait..." />;
    }
 
    const { isLoginValidated, isLoaderVisible } = this.state;
 
    return (
      <React.Fragment>
        <Dimmer active={isLoaderVisible}>
          <Loader active={isLoaderVisible} content="Please wait.." />
        </Dimmer>
 
        <div
          className="login-form background-image"
          style={{
            background: `url(${STATIC_FILE_PATH}Building_Image_4_full_dimmed_compressed.jpg) no-repeat center center fixed`,
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover"
          }}
        >
          <style>{`
            body > div#root,
            body > div > div#app,
            body > div > div > div.login-form {
              height: 100%;
            }
          `}</style>
          <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle" relaxed>
            <Grid.Column style={{ maxWidth: 450 }}>
              {isLoginValidated ? this.renderOTPForm() : this.renderLoginForm()}
              {/* {isLoginValidated && this.state.IS2FAEnabled ? this.renderOTPForm() : this.handleLoginWithoutOtp()} */}
            </Grid.Column>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
 
const mapStateToProps = (state, props) => {
  return {
    auth: state.auth
  };
};
 
export default connect(
  mapStateToProps,
  { signIn, authenticateToken }
)(LoginPage);