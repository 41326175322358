import axios from "axios";
import { API_BASE_URL } from "../applicationConstants";
import { applyInterceptor } from "./interceptor";

export default applyInterceptor(
  axios.create({
    baseURL: `${API_BASE_URL}/query`,
    withCredentials: true
  })
);
